import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Icon, Pressable, StyledText } from 'src/components';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { TableOfContentsItem } from 'src/features/content/types';
import { ifWeb, palette, typography } from 'src/styles';

interface Props {
  items: TableOfContentsItem[];
  inDropdown?: boolean;
  onItemPress?: () => void;
  active?: string;
}

export const TableOfContentsMenu: React.FC<Props> = ({ items, inDropdown, onItemPress, active }) => {
  const { primary } = useTheme();

  const handleItemPress = (item: TableOfContentsItem) => {
    onItemPress?.();
    item.onPress();
  };

  return (
    <>
      {items.map((item) => (
        <View
          style={styles.item}
          key={item.id}
          testID="toc-item"
          dataSet={{ isActive: item.id === active }}
        >
          <Pressable onPress={() => handleItemPress(item)}>
            {(hovered) => {
              const isActive = item.id === active;

              return (
                <StyledText
                  style={[
                    styles.text,
                    { color: primary },
                    typography.body2,
                    isActive && styles.textActive,
                    hovered && !isActive && styles.textHover,
                  ]}
                >
                  {!inDropdown && (
                    <View style={styles.activeIconWrapper}>
                      {isActive && (
                        <Icon
                          width={16}
                          name="arrow-right-long"
                          color={palette.navy}
                          testID="arrow-right-icon"
                        />
                      )}
                    </View>
                  )}
                  {item.title}
                </StyledText>
              );
            }}
          </Pressable>
        </View>
      ))}
    </>
  );
};

const styles = StyleSheet.create({
  item: {
    marginBottom: 12,
    position: 'relative',
  },
  text: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: 3,
    ...ifWeb({
      cursor: 'pointer',
      transitionProperty: 'color',
    }),
  },
  textHover: {
    color: palette.navy,
  },
  textActive: {
    color: palette.navy,
  },
  activeIconWrapper: {
    width: 24,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
});
