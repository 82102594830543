import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, StyleProp, TextStyle } from 'react-native';

import { ContentTypeBadge as ContentTypeBadgeOld } from 'src/components/ContentTypeBadge.old';
import { useUserInfo } from 'src/features/auth/hooks';
import { Contents } from 'src/features/content/config';
import { ContentStatus, ContentType } from 'src/features/content/constants';
import { palette } from 'src/styles';

import { Badge } from './Badge';
import { StatusBadge } from './StatusBadge';

interface BadgeProps {
  style: StyleProp<TextStyle>;
  label: string;
}

const getContentBadgeProps = (contentType: ContentType): BadgeProps => {
  switch (Contents.getSimplifiedType(contentType)) {
    case 'appendix': {
      return {
        label: 'appendix',
        style: { color: palette.blue },
      };
    }
    case 'drug': {
      return {
        label: 'drug',
        style: { color: palette.darkBlue },
      };
    }
    case 'algorithm': {
      return {
        label: 'algorithm',
        style: { color: palette.blue5 },
      };
    }
    case 'dx-tx': {
      return {
        label: 'clinicalMonograph',
        style: { color: palette.turquoise },
      };
    }
    case 'drug-handout':
    case 'clinical-handout': {
      return {
        label: 'handout',
        style: { color: palette.easternBlue },
      };
    }
    case 'ddx': {
      return {
        label: 'ddx',
        style: { color: palette.purple, textTransform: 'none' },
      };
    }
  }
};

export interface Props {
  contentType: ContentType;
  statusType?: ContentStatus;
  showBoth?: boolean;
  contentListSpacing?: boolean;
}

export const ContentTypeBadgeNew: React.FC<Props> = ({
  contentType,
  statusType,
  showBoth,
  contentListSpacing,
}) => {
  const { t } = useTranslation('contentTypeLabel');

  const badgeProps = getContentBadgeProps(contentType);

  if (!badgeProps) return null;

  const hasStatus = !!statusType && statusType !== ContentStatus.None;

  const contentBadge =
    (hasStatus && showBoth) || !hasStatus ? (
      <Badge badgeText={t(badgeProps.label)} textStyle={badgeProps.style} testID="content-badge" />
    ) : null;

  const statusBadge = hasStatus ? (
    <StatusBadge
      statusType={statusType}
      style={[showBoth && styles.spacing, contentListSpacing && styles.contentListSpacing]}
    />
  ) : null;

  return (
    <View style={styles.wrapper}>
      {contentBadge}
      {statusBadge}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
  },
  spacing: {
    marginLeft: 12,
  },
  contentListSpacing: {
    marginLeft: 8,
  },
});

export const ContentTypeBadge: React.FC<Props> = (props) => {
  const {
    permissions: { hasAccessToStandards },
  } = useUserInfo();
  const Component = hasAccessToStandards ? ContentTypeBadgeNew : ContentTypeBadgeOld;

  return <Component {...props} />;
};
