import React from 'react';

import { ContentListItemCardSimplified } from 'src/components/ContentListItem/ContentListItemCardSimplified';
import { getRouteBasedOnContentType, isWeb } from 'src/helpers';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { useSideBarContext } from 'src/navigation/contexts';

import { FavoritesItem } from '../types';

interface Props extends FavoritesItem {
  first?: boolean;
  last?: boolean;
}

export const FavoritesCard: React.FC<Props> = ({
  entryTitle,
  contentId,
  contentType,
  first,
  last,
  sideBar,
}) => {
  const route = getRouteBasedOnContentType(contentType);
  const { setSideBarOpen, isSidebarPermanentlyVisible } = useSideBarContext();
  const { isTablet } = useDeviceInfo();

  const handleItemPress = () => {
    if (!isSidebarPermanentlyVisible) {
      setSideBarOpen(false);
    }
  };

  return (
    <ContentListItemCardSimplified
      testID="favorites-card"
      contentType={contentType}
      title={entryTitle}
      topBorder={!isWeb && first}
      bottomBorder={!isWeb || (isWeb && !last)}
      sideBorder={isTablet}
      roundedTopBorder={isTablet && first}
      roundedBottomBorder={isTablet && last}
      favoriteItem
      linkProps={{
        to: {
          route,
          params: { id: contentId, source: 'home-screen-favorites' },
        },
      }}
      onPress={handleItemPress}
      sideBar={sideBar}
    />
  );
};
