import { ButtonColors } from 'src/components';
import { useUserInfo } from 'src/features/auth/hooks';
import { palette, plumbsPalette, standardsPalette } from 'src/styles';

export const useTheme = () => {
  const {
    isPro,
    permissions: { hasAccessToStandards },
  } = useUserInfo();
  const standardsTheme = isPro && hasAccessToStandards;
  const primary = standardsTheme ? standardsPalette.primary : plumbsPalette.primary;

  const quickLinkButtonColors: ButtonColors = {
    default: { primary: palette.grey2, content: primary },
    active: { primary: palette.grey4, content: palette.navy },
    disabled: { primary: palette.grey2, content: palette.grey4 },
  };

  const featureButtonColors: ButtonColors = {
    default: { content: palette.navy, primary: palette.grey2 },
    active: { content: primary, primary: palette.grey4 },
    disabled: { content: palette.grey2, primary: palette.grey2 },
  };

  return {
    primary,
    quickLinkButtonColors,
    featureButtonColors,
  };
};
