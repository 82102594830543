import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { BaseModal, Pressable, StyledText } from 'src/components';
import { ModalPortal } from 'src/contexts/portals';
import { getMessagesFromErrorResponse } from 'src/errorHandling/utils';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { GroupInvitationMessage } from 'src/features/subscription';
import { showNotification } from 'src/helpers';
import { useGroupInvitation } from 'src/hooks/useGroupInvitation';
import { Route } from 'src/navigation';
import { useRoute } from 'src/navigation/hooks';
import { typography } from 'src/styles';

import { useUserNotificationAction } from '../hooks';
import type { GroupInvitationNotification } from '../types';

interface Props {
  data: GroupInvitationNotification;
}

export const GroupInvitationNotificationModal: React.FC<Props> = ({ data }) => {
  const { primary } = useTheme();
  const { t } = useTranslation('groupInvitation');
  const { termsAccepted, setTerms, acceptGroupInvitation, isAcceptGroupInvitationPending } =
    useGroupInvitation();

  const { name: routeName } = useRoute();

  const { mutate: closeUserNotification } = useUserNotificationAction(data.id, 'close');
  const { mutate: rejectUserNotification } = useUserNotificationAction(data.id, 'reject');

  const handleSubmit = () => {
    acceptGroupInvitation(
      { membershipId: data.details.membershipId },
      {
        onError: (err: any) => {
          const status = err?.response?.status;
          if (status === 404) {
            showNotification({ type: 'error', title: t('invitationInvalid') });
            closeUserNotification();
          } else {
            const { detail } = getMessagesFromErrorResponse<{ detail?: string }>(err);
            showNotification({ type: 'error', title: detail });
          }
        },
      },
    );
  };

  if (routeName === Route.GroupInvitation) {
    return null;
  }

  return (
    <ModalPortal>
      <BaseModal
        style={styles.modal}
        close={() => closeUserNotification()}
        testID="group-invitation-notification"
      >
        <GroupInvitationMessage
          groupName={data.details.groupName}
          invitedAsSupportStaff={data.details.role === 'support-staff'}
          isLoading={isAcceptGroupInvitationPending}
          onSubmit={handleSubmit}
          termsAccepted={termsAccepted}
          onTermsChange={setTerms}
        />
        <Pressable onPress={() => rejectUserNotification()}>
          <StyledText style={[{ color: primary }, styles.rejectLinkText]} testID="modal-cancel-btn">
            {t('noThanks')}
          </StyledText>
        </Pressable>
      </BaseModal>
    </ModalPortal>
  );
};

const styles = StyleSheet.create({
  modal: {
    maxWidth: 522,
  },
  rejectLinkText: {
    ...typography.body2SemiBold,
    alignSelf: 'center',
    cursor: 'pointer',
    paddingVertical: 12,
  },
});
