import React, { ComponentProps } from 'react';
import { ViewStyle, StyleProp, StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';

import { MobileContentHeader, NumberIndicator } from 'src/components';
import { useUserInfo } from 'src/features/auth/hooks';
import { handoutsSelector } from 'src/features/handouts';
import { MixpanelEvent, MixpanelService, MixpanelToolsButton } from 'src/features/tracking';
import { i18n } from 'src/locale';
import { ToolsMenu as ToolsMenuOld } from 'src/navigation/components/SideBar/ToolsMenu.old';
import { Route } from 'src/navigation/routes';
import { palette } from 'src/styles';

import { CalculatorItem } from './CalculatorItem';
import { SideBarItems } from './SideBarItems';
import { SideBarLink } from './SideBarLink';

interface Props {
  style?: StyleProp<ViewStyle>;
}

export const ToolsMenuNew: React.FC<Props> = ({ style }) => {
  const { selectedHandouts } = useSelector(handoutsSelector);
  const {
    permissions: { canAccessIndividualAccountFeatures },
  } = useUserInfo();
  const { isPro } = useUserInfo();

  const getColorsForNumberOfGuides = (
    selected: boolean,
  ): Partial<ComponentProps<typeof NumberIndicator>> => {
    if (isPro) {
      return { backgroundColor: palette.white, color: palette.navy };
    }
    if (selected) {
      return { backgroundColor: palette.white, color: palette.navy };
    }
    if (selectedHandouts.length === 0) {
      return { backgroundColor: palette.grey4 };
    }
    return { backgroundColor: palette.navy };
  };

  const trackMixpanelEvent = (tabName: MixpanelToolsButton) => {
    MixpanelService.track(MixpanelEvent.ToolsButtonClicked, { 'Tool Name': tabName });
  };

  return (
    <View style={style} testID="tools-menu">
      <MobileContentHeader style={styles.sidebarHeading}>
        {i18n.t('tabNavigator:tools')}
      </MobileContentHeader>
      <SideBarItems>
        <SideBarLink
          to={{ route: Route.SelectedHandouts }}
          text={i18n.t('tools:selectedHandouts')}
          isFirst
          testID="nav-link-selected-handouts"
          onPress={() => trackMixpanelEvent('Selected Handouts')}
        >
          {(selected) => (
            <NumberIndicator
              text={selectedHandouts.length}
              {...getColorsForNumberOfGuides(selected)}
              testID="selected-handouts-counter"
            />
          )}
        </SideBarLink>
        {canAccessIndividualAccountFeatures && (
          <SideBarLink
            to={{ route: Route.Notes }}
            text={i18n.t('tools:notes')}
            testID="nav-link-notes"
            onPress={() => trackMixpanelEvent('Notes')}
          />
        )}
        <CalculatorItem onOpen={() => trackMixpanelEvent('Calculator')} />
      </SideBarItems>
    </View>
  );
};

const styles = StyleSheet.create({
  sidebarHeading: {
    color: palette.white,
    marginBottom: 12,
  },
});

export const ToolsMenu: React.FC<Props> = (props) => {
  const {
    permissions: { hasAccessToStandards },
  } = useUserInfo();
  const Component = hasAccessToStandards ? ToolsMenuNew : ToolsMenuOld;

  return <Component {...props} />;
};
