import React from 'react';
import { StyleSheet, View } from 'react-native';

import { useTheme } from 'src/features/auth/hooks/useTheme';
import { palette } from 'src/styles';

import { Pressable } from './Pressable';
import { Switch as BaseComponent } from './Switch';

const TRACK_WIDTH = 46;
const TRACK_HEIGHT = 24;
const THUMB_SIZE = 20;
const THUMB_MARGIN = (TRACK_HEIGHT - THUMB_SIZE) / 2;
const MAX_X_VALUE = TRACK_WIDTH - THUMB_SIZE - THUMB_MARGIN * 2;

type Props = React.ComponentProps<typeof BaseComponent>;

export const Switch: React.FC<Props> = ({ checked, onChange, testID }) => {
  const { primary } = useTheme();
  return (
    <Pressable onPress={() => onChange(!checked)}>
      <View
        role="switch"
        aria-checked={checked}
        testID={testID || 'switch-btn'}
        style={[styles.container, checked && { backgroundColor: primary }]}
      >
        <View style={[styles.thumb, checked && styles.thumbActive]} />
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    width: TRACK_WIDTH,
    borderRadius: TRACK_HEIGHT / 2,
    height: TRACK_HEIGHT,
    cursor: 'pointer',
    transition: 'background-color 0.25s',
    backgroundColor: palette.grey2,
  },
  thumb: {
    width: THUMB_SIZE,
    height: THUMB_SIZE,
    borderRadius: THUMB_SIZE / 2,
    margin: THUMB_MARGIN,
    position: 'absolute',
    backgroundColor: palette.white,
    transition: 'transform 0.25s',
    transform: [{ translateX: 0 }],
  },
  thumbActive: {
    transform: [{ translateX: MAX_X_VALUE }],
  },
});
