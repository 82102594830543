import React from 'react';
import { StyleSheet, StyleProp, TextStyle } from 'react-native';

import { useTheme } from 'src/features/auth/hooks/useTheme';
import { Link } from 'src/navigation/components';
import { typography, palette } from 'src/styles';

import { Pressable } from './Pressable';
import { StyledText } from './StyledText';

type LinkPropsNoChildren = Omit<React.ComponentProps<typeof Link>, 'children'>;

interface Props extends Partial<LinkPropsNoChildren> {
  bold?: boolean;
  style?: StyleProp<TextStyle>;
  title?: string;
  children?: React.ReactNode;
}

export const TextButton: React.FC<Props> = (props) => {
  const { primary } = useTheme();
  const { bold = true, style, title, children, onPress, testID, to, ...rest } = props;

  const getContent = (hovered: boolean, pressed: boolean) => (
    <StyledText
      style={[
        bold ? typography.body2Bold : typography.body2,
        styles.link,
        { color: primary },
        hovered && styles.linkHover,
        pressed && styles.linkActive,
        style,
      ]}
    >
      {title || children}
    </StyledText>
  );

  return to ? (
    <Link to={to} {...rest} testID={testID}>
      {getContent}
    </Link>
  ) : (
    <Pressable onPress={onPress} testID={testID}>
      {getContent}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  link: {
    display: 'flex',
  },
  linkHover: {
    color: palette.blue2,
    textDecorationLine: 'underline',
  },
  linkActive: {
    color: palette.darkBlue,
    textDecorationLine: 'none',
  },
});
