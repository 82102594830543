import { StyleSheet } from 'react-native';

import { StyledText } from 'src/components';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { i18n } from 'src/locale';
import { typography } from 'src/styles';

interface Props {}

export const VerifiedStudentMessage: React.FC<Props> = () => {
  const { primary } = useTheme();
  return (
    <StyledText style={[{ color: primary }, styles.text, typography.body3Bold]}>
      {i18n.t('subscriptionProcess:verifiredStudentMessage')}
    </StyledText>
  );
};

const styles = StyleSheet.create({
  text: {
    marginBottom: 30,
  },
});
