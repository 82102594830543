import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ScrollView, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { Pressable } from 'src/components/Pressable';
import { StyledText } from 'src/components/StyledText';
import { SelectOption, SelectOptions } from 'src/constants/types';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { getSelectOptionsWithoutGroups, isSelectOptionsGroup } from 'src/helpers';
import { Key, useKeyboardPress } from 'src/hooks';
import { i18n } from 'src/locale';
import { getShadow, ifWeb, palette, typography } from 'src/styles';

interface Props {
  isOpen: boolean;
  maxDropdownHeight?: number;
  options: SelectOptions;
  value?: string | null;
  onChange: (option: SelectOption) => void;
  style?: StyleProp<ViewStyle>;
}

export const SelectDropdown: React.FC<Props> = ({
  isOpen,
  maxDropdownHeight,
  options,
  value,
  onChange,
  style,
}) => {
  const { primary } = useTheme();
  const [focusedOptionIndex, setFocusedOption] = useState(0);
  const scrollViewRef = useRef<ScrollView>(null);

  const optionsWithoutGroups = useMemo(() => getSelectOptionsWithoutGroups(options), [options]);

  const scrollToOption = useCallback((value?: string) => {
    const elementToScroll = (scrollViewRef.current as unknown as HTMLDivElement).children[0]?.querySelector(
      `[data-option-value='${value}']`,
    ) as HTMLSpanElement;
    const top = elementToScroll?.offsetTop;
    scrollViewRef.current?.scrollTo({ y: top });
  }, []);

  const handleEnterPress = useCallback(() => {
    const option = optionsWithoutGroups[focusedOptionIndex];
    if (option) {
      onChange(option);
    }
  }, [optionsWithoutGroups, focusedOptionIndex, onChange]);

  const handleArrowPress = useCallback(
    (key: Key, e: KeyboardEvent) => {
      e.preventDefault();
      const delta = key === 'ArrowDown' ? 1 : -1;
      setFocusedOption((state) => {
        const newIndex = state + delta;
        const newIndexInRange =
          newIndex === -1
            ? optionsWithoutGroups.length - 1
            : newIndex >= optionsWithoutGroups.length
            ? 0
            : newIndex;

        scrollToOption(optionsWithoutGroups[newIndexInRange]?.value);
        return newIndexInRange;
      });
    },
    [optionsWithoutGroups, scrollToOption],
  );

  useKeyboardPress(
    isOpen && { ArrowDown: handleArrowPress, ArrowUp: handleArrowPress, Enter: handleEnterPress },
  );

  useEffect(() => {
    if (isOpen && value) {
      const index = optionsWithoutGroups.findIndex((item) => item.value === value);
      if (index > -1) {
        setFocusedOption(index);
        scrollToOption(value);
      } else {
        setFocusedOption(0);
      }
    }
  }, [isOpen, value, optionsWithoutGroups, scrollToOption]);

  const renderOption = useCallback(
    (option: SelectOption, isFirst = false) => (
      <div
        data-option-value={option.value}
        data-option-label={option.label}
        data-testid="select-option"
        key={option.label}
      >
        <Pressable focusable={false} onPress={() => onChange(option)} disabled={option.isDisabled}>
          {(isHovered) => (
            <View
              style={[
                styles.option,
                isFirst && styles.firstItem,
                (optionsWithoutGroups[focusedOptionIndex]?.value === option.value ||
                  (isHovered && !option.isDisabled)) &&
                  styles.optionFocused,
              ]}
            >
              <StyledText
                style={[
                  typography.body2,
                  option.isDisabled && styles.optionDisabledText,
                  option.value === value && [typography.weightBold, { color: primary }],
                ]}
              >
                {option.label}
              </StyledText>
            </View>
          )}
        </Pressable>
      </div>
    ),
    [onChange, value, focusedOptionIndex, optionsWithoutGroups, primary],
  );

  const optionsWithFilteredGroups: SelectOptions = options.filter((option) =>
    isSelectOptionsGroup(option) ? !!option.options?.length : true,
  );

  const optionsToDisplay = optionsWithFilteredGroups.length
    ? optionsWithFilteredGroups
    : [
        {
          value: null,
          isDisabled: true,
          label: i18n.t('search:noResultsTitle'),
        },
      ];

  return (
    <ScrollView
      style={[
        styles.dropdown,
        isOpen && [styles.dropdownOpen, !!maxDropdownHeight && { maxHeight: maxDropdownHeight }],
        style,
      ]}
      focusable={false}
      ref={scrollViewRef}
      testID="select-options"
    >
      {isOpen
        ? optionsToDisplay.map((option, index) =>
            isSelectOptionsGroup(option) ? (
              <React.Fragment key={option.label}>
                <View style={[styles.groupHeader, !index && styles.firstItem]}>
                  <StyledText style={[{ color: primary }, typography.body2Bold]}>
                    {option.label.toUpperCase()}
                  </StyledText>
                </View>
                {option.options.map((optionNested) => renderOption(optionNested))}
              </React.Fragment>
            ) : (
              renderOption(option, !index)
            ),
          )
        : renderOption({ label: '' })}
      {/* Dummy option rendered to make CSS transition look better */}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  dropdown: {
    maxHeight: 200,
    ...getShadow(4, 0.5),
    position: 'absolute',
    width: '100%',
    zIndex: 1,
    backgroundColor: palette.white,
    transform: [
      {
        scaleY: 0,
      },
    ],
    ...ifWeb({
      transformOrigin: 'top center',
      transitionProperty: 'transform',
    }),
  },
  dropdownOpen: {
    transform: [
      {
        scaleY: 1,
      },
    ],
  },
  option: {
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderTopColor: palette.grey2,
    borderTopWidth: 1,
  },
  optionFocused: {
    backgroundColor: palette.grey1,
  },
  optionDisabledText: {
    color: palette.grey5,
  },
  groupHeader: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    backgroundColor: palette.brightBlue2,
    borderTopColor: palette.grey2,
    borderTopWidth: 1,
  },
  firstItem: {
    borderTopWidth: 0,
  },
});
