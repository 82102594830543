import React, { useCallback } from 'react';
import { StyleSheet, View, ScrollView } from 'react-native';

import { SortParams, TableColumn } from 'src/constants/types';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { isWeb } from 'src/helpers';
import { i18n } from 'src/locale';
import { ifWeb, palette, typography } from 'src/styles';

import { DividerButton } from '../DividerButton';
import { Icon } from '../Icon/Icon';
import { LoadingIndicator } from '../LoadingIndicator';
import { Pressable } from '../Pressable';
import { StyledText } from '../StyledText';

interface Props<D extends Record<string, any>> {
  data: D[];
  columns: TableColumn<D>[];
  onHeaderPress: (key: string) => void;
  sortParams: SortParams;
  minWidth?: number;
  columnsWidths?: (number | string)[];
  simplified?: boolean;
  displayViewAllButton?: boolean;
  onViewAllPress?: () => void;
  isLoading?: boolean;
}

export function TableView<D extends Record<string, any>>({
  columns,
  data,
  minWidth,
  columnsWidths = [],
  onHeaderPress,
  sortParams,
  simplified,
  displayViewAllButton,
  onViewAllPress,
  isLoading,
}: Props<D>): React.ReactElement {
  const { primary } = useTheme();
  const getColumnWidth = useCallback(
    (index: number) => columnsWidths[index] || (isWeb ? undefined : Math.floor(100 / columns.length) + '%'),
    [columnsWidths, columns.length],
  );

  const getColumnIndex = (column: TableColumn<D>) => columns.findIndex((item) => item.key === column.key);

  const renderHeaderCell = (column: TableColumn<D>) => {
    const index = getColumnIndex(column);
    const width = getColumnWidth(index);
    const key = column.key as string;

    const caretIcon = !column.sort ? null : (
      <Icon
        name={simplified ? 'chevron-down' : 'caret-down'}
        color={sortParams.sortBy === key ? primary : palette.grey5}
        width={10}
        style={[
          styles.tableHeaderIcon,
          sortParams.sortBy === key && sortParams.order === 'asc' && styles.tableHeaderIconRotated,
        ]}
      />
    );

    const content = (
      <>
        <StyledText style={[typography.body1ShortSemiBold]} singleLine>
          {column.header}
        </StyledText>
        {caretIcon}
      </>
    );

    return isWeb ? (
      <th
        key={key}
        style={StyleSheet.flatten([
          styles.td,
          { width },
          simplified && styles.thSimplified,
          column.sort && styles.clickable,
        ])}
        onClick={column.sort ? () => onHeaderPress(key) : undefined}
      >
        <View
          style={[
            styles.cell,
            simplified && styles.cellHeaderSimplified,
            column.align === 'right' && styles.cellAlignRight,
          ]}
        >
          {content}
        </View>
      </th>
    ) : column.sort ? (
      <Pressable onPress={() => onHeaderPress(key)} key={key}>
        <View style={[styles.cell, { width }, column.align === 'right' && styles.cellAlignRight]}>
          {content}
        </View>
      </Pressable>
    ) : (
      <View style={[styles.cell, { width }, column.align === 'right' && styles.cellAlignRight]} key={key}>
        {content}
      </View>
    );
  };

  const renderBodyCell = (row: D, column: TableColumn<D>, lastRow?: boolean) => {
    const key = column.key as string;
    const content = column.renderCell?.(row) || row[key];
    const contentElement = ['string', 'number'].includes(typeof content) ? (
      <StyledText style={[simplified ? typography.body1 : typography.body1Short]}>{content}</StyledText>
    ) : (
      content
    );
    const index = getColumnIndex(column);
    const width = getColumnWidth(index);

    return isWeb ? (
      <td
        style={StyleSheet.flatten([
          styles.td,
          { width },
          simplified && styles.tdSimplified,
          lastRow && displayViewAllButton && styles.tdNoBorder,
        ])}
        key={key}
      >
        <View
          style={[styles.cell, styles.cellBody, column.align === 'right' && styles.cellAlignRight]}
          testID={column.testID}
        >
          {contentElement}
        </View>
      </td>
    ) : (
      <View
        key={key}
        style={[styles.cell, styles.cellBody, column.align === 'right' && styles.cellAlignRight, { width }]}
      >
        {contentElement}
      </View>
    );
  };

  return (
    <>
      <ScrollView
        style={styles.wrapper}
        horizontal
        contentContainerStyle={styles.scrollViewContentContainer}
      >
        {isWeb ? (
          <table style={StyleSheet.flatten([styles.table, minWidth !== undefined && { minWidth }])}>
            <thead>
              <tr style={StyleSheet.flatten(styles.rowHeader)}>
                {columns.map((headerCell) => renderHeaderCell(headerCell))}
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={columns.length}>
                    <LoadingIndicator style={styles.loadingIndicator} />
                  </td>
                </tr>
              ) : (
                data.map((row, index) => (
                  <tr
                    style={StyleSheet.flatten([
                      styles.row,
                      styles.rowBody,
                      !simplified && index % 2 !== 0 && styles.rowBodyEven,
                    ])}
                    key={index}
                  >
                    {columns.map((column) => renderBodyCell(row, column, index === data.length - 1))}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        ) : (
          <View style={[styles.table, minWidth !== undefined && { minWidth }]}>
            <View style={[styles.row, styles.rowHeader]}>
              {columns.map((headerCell) => renderHeaderCell(headerCell))}
            </View>
            {data.map((row, index) => (
              <View style={[styles.row, styles.rowBody, index % 2 !== 0 && styles.rowBodyEven]} key={index}>
                {columns.map((column) => renderBodyCell(row, column))}
              </View>
            ))}
          </View>
        )}
      </ScrollView>
      {displayViewAllButton && (
        <DividerButton title={i18n.t('viewAll')} style={styles.viewAllButton} onPress={onViewAllPress} />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
  },
  scrollViewContentContainer: {
    width: '100%',
    alignItems: 'flex-start',
  },
  table: {
    width: '100%',
    ...ifWeb(
      {
        borderCollapse: 'collapse' as const,
      },
      {
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderColor: palette.grey2,
      },
    ),
  },
  td: {
    borderWidth: 1,
    borderColor: palette.grey2,
    borderStyle: 'solid',
  },
  tdSimplified: {
    borderWidth: 0,
    borderBottomWidth: 1,
  },
  tdNoBorder: {
    borderWidth: 0,
    borderBottomWidth: 0,
  },
  cellAlignRight: {
    justifyContent: 'flex-end',
  },
  thSimplified: {
    borderWidth: 0,
  },
  row: {
    width: '100%',
    flexDirection: 'row',
  },
  rowHeader: {
    backgroundColor: palette.grey1,
  },
  rowBody: {
    backgroundColor: palette.white,
  },
  rowBodyEven: {
    backgroundColor: palette.brightBlue2,
  },
  cell: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: 'center',
    flexDirection: 'row',
    ...ifWeb(
      {
        width: '100%',
        height: '100%',
      },
      {
        borderBottomWidth: 1,
        borderRightWidth: 1,
        borderColor: palette.grey2,
      },
    ),
  },
  cellBody: {
    ...ifWeb(
      {
        paddingTop: 18,
        paddingBottom: 18,
      },
      {
        paddingVertical: 18,
      },
    ),
  },
  cellHeaderSimplified: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  tableHeaderIcon: {
    marginLeft: 10,
  },
  tableHeaderIconRotated: {
    transform: [
      {
        rotate: '180deg',
      },
    ],
  },
  viewAllButton: {
    marginTop: -10,
  },
  clickable: {
    ...ifWeb({
      cursor: 'pointer',
    }),
  },
  loadingIndicator: {
    marginTop: 50,
    marginBottom: 20,
  },
});
