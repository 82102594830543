import { Trans, useTranslation } from 'react-i18next';
import { Animated, StyleSheet } from 'react-native';

import { BaseButton, InfoBox, TextArea } from 'src/components';
import { TERMS_AND_CONDITIONS_LINK } from 'src/constants/externalLinks';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { Link } from 'src/navigation/components';

import { useHandoutsPrintAndDownload } from '../hooks';
import { useHandoutMarginAnimation } from '../hooks/useHandoutMarginAnimation';
import type { ShareHandoutsFormProps } from '../types';

interface Props extends ShareHandoutsFormProps {}

export const DownloadHandouts: React.FC<Props> = ({ onShareSuccess, language, LanguageSelector }) => {
  const { primary } = useTheme();
  const { t } = useTranslation('handouts');
  const { generateAndPrint, loading, noteValue, selectedHandouts, setNoteValue } =
    useHandoutsPrintAndDownload({
      action: 'download',
      onSuccess: onShareSuccess,
      language,
    });
  const marginAnimation = useHandoutMarginAnimation();

  return (
    <Animated.View style={{ marginBottom: marginAnimation }} testID="download-handouts-form">
      <TextArea
        label={t('addInstructions')}
        placeholder={t('addInstructionsPlaceholder')}
        readOnly={loading}
        onChangeText={(note: string) => setNoteValue(note)}
        value={noteValue}
        testID="input-download-content"
      />
      <InfoBox style={styles.infoBox} testID="download-handout-disclaimer">
        <Trans
          i18nKey="handouts:downloadDisclaimer"
          components={{
            terms: (
              <Link wrapper="text" external to={TERMS_AND_CONDITIONS_LINK} style={{ color: primary }} />
            ),
          }}
        />
      </InfoBox>
      {LanguageSelector}
      <BaseButton
        title={t('download', { count: selectedHandouts.length })}
        variant="gradient"
        icon="chevron-right-circle"
        containerStyle={styles.submitButton}
        titleStyle={styles.submitButtonTitle}
        loading={loading}
        onPress={generateAndPrint}
        testID="download-handout-btn"
      />
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  infoBox: {
    marginTop: 28,
  },
  submitButton: {
    alignSelf: 'flex-start',
    marginTop: 28,
  },
  submitButtonTitle: {
    textTransform: 'none',
  },
});
