import React from 'react';

import { useTheme } from 'src/features/auth/hooks/useTheme';
import { palette } from 'src/styles';

import { BaseButton, BaseButtonProps } from './BaseButton';

export const PrimaryButton: React.FC<BaseButtonProps> = (props) => {
  const { primary } = useTheme();

  const colors = {
    default: { primary: primary, content: palette.white },
    active: { primary: palette.navy, content: palette.white },
    disabled: { primary: palette.grey2, content: palette.white },
  };

  return (
    <BaseButton
      {...props}
      hoverStyle={{
        backgroundColor: colors.active.primary,
      }}
      colors={colors}
    />
  );
};
