import { StyleProp, StyleSheet, View, ViewStyle, TextStyle } from 'react-native';

import { IconName } from 'src/constants/types';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { Link } from 'src/navigation/components';
import { ifWeb, palette, typography } from 'src/styles';

import { Icon } from './Icon/Icon';
import { LoadingIndicator } from './LoadingIndicator';
import { MarkdownContent } from './MarkdownContent/MarkdownContent';
import { Pressable } from './Pressable';
import { StyledText } from './StyledText';

export interface IconTextButtonProps
  extends Partial<Pick<React.ComponentProps<typeof Link>, 'to' | 'external'>> {
  text: string;
  icon: IconName;
  iconWidth?: number;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  iconPosition?: 'left' | 'right';
  iconColor?: string;
  iconColorHovered?: string;
  textStyle?: StyleProp<TextStyle>;
  iconStyle?: StyleProp<ViewStyle>;
  testID?: string;
  disabled?: boolean;
  asMarkdown?: boolean;
  id?: string;
  circleIcon?: boolean;
  circleColor?: string;
  isLoading?: boolean;
  underlineAtHover?: boolean;
}

export const IconTextButton: React.FC<IconTextButtonProps> = ({
  text,
  icon,
  onPress,
  style,
  iconPosition = 'right',
  textStyle,
  iconStyle,
  testID,
  disabled,
  isLoading,
  asMarkdown,
  circleIcon,
  circleColor,
  iconWidth = circleIcon ? 11 : 28,
  iconColor,
  underlineAtHover,
  external,
  to,
}) => {
  const { primary } = useTheme();
  const assignedCircleColor = circleColor || primary;
  const assignedIconColor = iconColor || circleIcon ? palette.white : primary;
  const iconWrapperStyles = [
    styles.iconWrapper,
    circleIcon && [styles.iconWrapperCircle, { backgroundColor: assignedCircleColor }],
  ];

  const getTextStyle = (isActive: boolean) => [
    styles.text,
    isActive && [
      { color: primary },
      underlineAtHover && [{ textDecorationColor: primary }, styles.textUnderline],
    ],
    textStyle,
  ];

  const inner = (isHovered: boolean, isPressed: boolean) => (
    <View style={[styles.wrapper, iconPosition === 'left' && styles.wrapperReverse, style]}>
      {asMarkdown ? (
        <MarkdownContent style={getTextStyle(isHovered || isPressed)} inline limited>
          {text}
        </MarkdownContent>
      ) : (
        <StyledText style={getTextStyle(isHovered || isPressed)}>{text}</StyledText>
      )}
      {isLoading ? (
        <LoadingIndicator size="small" style={styles.loadingIndicator} />
      ) : (
        <View style={iconWrapperStyles}>
          <Icon name={icon} color={assignedIconColor} width={iconWidth} style={iconStyle} />
        </View>
      )}
    </View>
  );

  if (to !== undefined) {
    return (
      <Link external={external} to={to} onPress={onPress} testID={testID} disabled={disabled}>
        {inner}
      </Link>
    );
  }

  return (
    <Pressable onPress={onPress} testID={testID} disabled={disabled}>
      {inner}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  wrapperReverse: {
    flexDirection: 'row-reverse',
  },
  iconWrapper: {
    width: 28,
    alignItems: 'center',
  },
  iconWrapperCircle: {
    height: 28,
    borderRadius: 14,
    justifyContent: 'center',
  },
  text: {
    paddingRight: 8,
    justifyContent: 'space-between',
    color: palette.navy,
    ...typography.body3Bold,
    ...ifWeb({
      textDecorationLine: 'underline',
      textDecorationColor: palette.transparent,
      transitionProperty: 'color, text-decoration-color',
    }),
  },
  textUnderline: {
    textDecorationLine: 'underline',
  },
  loadingIndicator: {
    alignItems: 'flex-start',
  },
});
