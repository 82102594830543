import { PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';

import { useUserInfo } from 'src/features/auth/hooks';
import { palette } from 'src/styles';

export const SideBarItems: React.FC<PropsWithChildren> = ({ children }) => {
  const {
    permissions: { hasAccessToStandards },
  } = useUserInfo();
  return <View style={[styles.wrapper, !hasAccessToStandards && styles.whiteBackground]}>{children}</View>;
};

const styles = StyleSheet.create({
  wrapper: {
    borderRadius: 5,
    overflow: 'hidden',
  },
  whiteBackground: {
    backgroundColor: palette.white,
  },
});
