import { StyleSheet, View } from 'react-native';

import {
  GradientBar,
  Icon,
  MobileContentHeader,
  NumberIndicator,
  Pressable,
  StyledText,
} from 'src/components';
import { useUserInfo } from 'src/features/auth/hooks';
import { MobileNavMenuItem as MobileNavMenuItemOld } from 'src/navigation/components/MobileNavMenuItem.old';
import { useNavigation } from 'src/navigation/hooks/useNavigation';
import { MobileNavMenuItemProps } from 'src/navigation/types';
import { ifWeb, palette, typography } from 'src/styles';

interface Props extends MobileNavMenuItemProps {}

export const MobileNavMenuItemNew: React.FC<Props> = ({
  title,
  description,
  route,
  icon,
  isProContent,
  titleStyle,
  iconWidth = 50,
  counter,
  onPress,
}) => {
  const navigation = useNavigation();

  const handlePress = () => {
    onPress?.();
    navigation.navigate(route);
  };

  const { isPro } = useUserInfo();

  return (
    <Pressable onPress={handlePress}>
      <View style={styles.wrapper}>
        <View style={styles.textWrapper}>
          <View style={styles.titleWrapper}>
            <MobileContentHeader style={titleStyle}>{title}</MobileContentHeader>
            {counter !== undefined && (
              <NumberIndicator
                text={counter}
                backgroundColor={counter === 0 ? palette.grey4 : palette.navy}
                style={styles.numberIndicator}
              />
            )}
            {isProContent && !isPro && (
              <View style={styles.standardsBadgeWrapper}>
                <GradientBar gradientType="proStandardsBadgeGradient">
                  <StyledText style={styles.standardsBadge}>Standards</StyledText>
                </GradientBar>
              </View>
            )}
          </View>
          <StyledText style={styles.description}>{description}</StyledText>
        </View>
        <View style={styles.icons}>
          <View style={styles.iconWrapper}>
            <Icon
              name={icon}
              strokeWidth={1.75}
              width={iconWidth}
              color={isPro ? 'standardsGradient' : 'plumbsGradient'}
            />
          </View>
          <Icon name="chevron-right" color={palette.navy} width={9} style={styles.chevron} />
        </View>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    paddingHorizontal: 14,
    paddingTop: 16,
    paddingBottom: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: palette.grey2,
  },
  textWrapper: {
    flex: 1,
    marginRight: 20,
  },
  titleWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 2,
  },
  iconWrapper: {
    width: 62,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5,
  },
  icons: {
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  chevron: {
    marginRight: 4,
  },
  description: {
    ...typography.text2,
    lineHeight: 20,
  },
  numberIndicator: {
    marginLeft: 8,
  },
  standardsBadgeWrapper: {
    overflow: 'hidden',
    marginLeft: 8,
    ...ifWeb(
      {
        borderRadius: 5,
      },
      {
        borderRadius: 3,
      },
    ),
  },
  standardsBadge: {
    fontSize: 12,
    fontWeight: 700,
    textTransform: 'uppercase',
    color: palette.white,
    borderRadius: 5,
    height: 20,
    paddingHorizontal: 6,
    paddingVertical: 2,
  },
});

export const MobileNavMenuItem: React.FC<Props> = (props) => {
  const {
    permissions: { hasAccessToStandards },
  } = useUserInfo();
  const Component = hasAccessToStandards ? MobileNavMenuItemNew : MobileNavMenuItemOld;

  return <Component {...props} />;
};
