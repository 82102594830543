import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { StyleSheet, View, ScrollView } from 'react-native';

import { Container, FadingBox, Pressable, StyledText } from 'src/components';
import { types } from 'src/constants';
import { useUserInfo } from 'src/features/auth/hooks';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { getScrollDivProperties, isWeb } from 'src/helpers';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { palette, TABS_BAR_HEIGHT, typography, getShadow, ifWeb } from 'src/styles';

interface Props {
  items: types.TabsBarItem[];
  withShadow?: boolean;
  testID?: string;
}

export const TabsBar: React.FC<Props> = ({ items, withShadow, testID }) => {
  const { primary } = useTheme();
  const { isTabletApp } = useDeviceInfo();
  const scrollView = useRef<ScrollView>(null);
  const [isLeftFadeDisplayed, setLeftFade] = useState(false);
  const [isRightFadeDisplayed, setRightFade] = useState(false);

  const {
    permissions: { hasAccessToStandards },
  } = useUserInfo();

  const setFadingBoxes = useCallback(() => {
    if (isWeb && scrollView.current) {
      const wrapper = scrollView.current as unknown as HTMLDivElement;
      const { isScrollDisplayed, isScrolledToLeft, isScrolledToRight } = getScrollDivProperties(wrapper);
      setLeftFade(isScrollDisplayed && !isScrolledToLeft);
      setRightFade(isScrollDisplayed && !isScrolledToRight);
    }
  }, []);

  useLayoutEffect(() => {
    setFadingBoxes();

    if (isWeb) {
      window.addEventListener('resize', setFadingBoxes);
      return () => window.removeEventListener('resize', setFadingBoxes);
    }
  }, [setFadingBoxes]);

  if (items.length < 2) {
    return null;
  }

  return (
    <View style={[styles.wrapper, withShadow && styles.wrapperShadow]} testID={testID}>
      <Container style={styles.container}>
        <ScrollView
          contentContainerStyle={[styles.itemsWrapperContent, isTabletApp && styles.tabletDeviceContainer]}
          style={styles.itemsWrapper}
          horizontal
          ref={scrollView}
          onScroll={setFadingBoxes}
          scrollEventThrottle={80}
          showsHorizontalScrollIndicator={false}
          testID="filter-tabs-bar"
        >
          {items.map((item) => (
            <Pressable onPress={item.onPress} key={item.key} testID="filter">
              {(isHovered, isPressed) => (
                <View
                  style={[styles.item, item.isActive && { borderColor: primary }]}
                  dataSet={{ isActive: item.isActive }}
                >
                  <StyledText style={[styles.itemText, (isHovered || isPressed) && { color: primary }]}>
                    {/* Replace with 'item.text' when Standards feature flag is removed */}
                    {!hasAccessToStandards && item.text === 'Clinical' ? 'Dx & Tx' : item.text}
                  </StyledText>
                </View>
              )}
            </Pressable>
          ))}
        </ScrollView>
        <FadingBox
          id="tabs-bar-left"
          width="100%"
          height="100%"
          style={[styles.fadingBox, isLeftFadeDisplayed && styles.fadingBoxVisible]}
          direction="left"
        />
        <FadingBox
          id="tabs-bar-right"
          width="100%"
          height="100%"
          style={[styles.fadingBox, styles.fadingBoxRight, isRightFadeDisplayed && styles.fadingBoxVisible]}
          direction="right"
        />
      </Container>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    height: TABS_BAR_HEIGHT,
    backgroundColor: palette.white,
    justifyContent: 'center',
  },
  wrapperShadow: {
    ...ifWeb({
      borderTopWidth: 1,
      borderTopColor: palette.grey2,
      ...getShadow(),
    }),
  },
  container: {
    alignItems: 'flex-start',
    flex: 1,
  },
  tabletDeviceContainer: {
    width: '100%',
  },
  itemsWrapper: {
    width: '100%',
  },
  itemsWrapperContent: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: 'auto',
  },
  item: {
    paddingHorizontal: 16,
    paddingVertical: 16,
    alignSelf: 'stretch',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 3,
    borderColor: palette.transparent,
    ...ifWeb({
      transitionProperty: 'border-color',
    }),
  },
  itemText: {
    color: palette.navy,
    ...typography.body3Bold,
    ...ifWeb({
      transitionProperty: 'color',
    }),
  },
  fadingBox: {
    position: 'absolute',
    width: 100,
    zIndex: 1,
    pointerEvents: 'none',
    opacity: 0,
    ...ifWeb({
      transitionProperty: 'opacity',
    }),
  },
  fadingBoxRight: {
    right: -1, // not sure why, but this 1px offset is necessary to cover the text completely
  },
  fadingBoxVisible: {
    opacity: 1,
  },
});
