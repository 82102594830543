import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { DropdownItem } from 'src/constants/types';
import { useUserInfo } from 'src/features/auth/hooks';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { getShadowNative, ifWeb, palette, typography } from 'src/styles';

import { GradientBar } from './GradientBar';
import { Pressable } from './Pressable';
import { ProBadge } from './ProBadge';
import { StyledText } from './StyledText';

export interface Props<T> {
  items: DropdownItem<T>[];
  value: string;
  onChange?: (item: DropdownItem<T>) => void;
  style?: StyleProp<ViewStyle>;
}

export const Dropdown = <T,>({ items, value, onChange, style }: Props<T>) => {
  const { primary } = useTheme();
  const handleItemPress = (item: DropdownItem<T>) => {
    onChange?.(item);
  };

  const {
    isPro,
    permissions: { hasAccessToStandards },
  } = useUserInfo();

  const visibleItems = items.filter((item) => !item.isHidden);

  return (
    <View style={[styles.itemsWrapper, style]} testID="filters-list">
      {visibleItems.map((item) => {
        const isActive = item.value === value;
        return (
          <Pressable key={item.label} onPress={() => handleItemPress(item)}>
            {(isHovered, isPressed) => (
              <View style={[styles.item, isActive && { backgroundColor: primary }]} testID={item.testID}>
                <StyledText
                  style={[
                    styles.itemText,
                    isActive && styles.itemActiveText,
                    (isHovered || isPressed) && !isActive && { color: primary },
                  ]}
                >
                  {item.listLabel ? item.listLabel : item.label}
                </StyledText>
                {hasAccessToStandards
                  ? !isPro &&
                    item.isPro && (
                      <View style={styles.standardsBadgeWrapper}>
                        <GradientBar gradientType="proStandardsBadgeGradient">
                          <StyledText style={styles.standardsBadge}>Standards</StyledText>
                        </GradientBar>
                      </View>
                    )
                  : item.isPro && (
                      <ProBadge style={styles.badge} variant={isActive ? 'inverted' : 'gradient'} />
                    )}
              </View>
            )}
          </Pressable>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  itemsWrapper: {
    backgroundColor: palette.white,
    padding: 4,
    borderRadius: 8,
    alignSelf: 'flex-start',
    ...getShadowNative(),
  },
  item: {
    padding: 8,
    borderRadius: 4,
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemText: {
    color: palette.navy,
    ...typography.body3SemiBold,
    ...ifWeb({
      transitionProperty: 'color',
    }),
  },
  itemActiveText: {
    color: palette.white,
  },
  badge: {
    marginLeft: 8,
  },
  standardsBadgeWrapper: {
    overflow: 'hidden',
    borderRadius: 5,
  },
  standardsBadge: {
    fontSize: 12,
    fontWeight: 700,
    textTransform: 'uppercase',
    color: palette.white,
    borderRadius: 5,
    height: 20,
    paddingLeft: 6,
    paddingRight: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
