import { FormikErrors, FormikTouched } from 'formik';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { Icon, InputItem, Pressable, StyledText, TextArea } from 'src/components';
import { useUserInfo } from 'src/features/auth/hooks';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { palette, typography } from 'src/styles';

import type { EmailHandoutsFormValues } from '../types';

interface Props {
  values: EmailHandoutsFormValues;
  handleOnChange: (id: string, value: string) => void;
  handleOnBlur: (id: string) => void;
  errors: FormikErrors<EmailHandoutsFormValues>;
  touched: FormikTouched<EmailHandoutsFormValues>;
  setValues: (values: EmailHandoutsFormValues, shouldValidate?: boolean | undefined) => any;
}

interface TogglerButtonProps {
  text: string;
  isActive?: boolean;
  onPress?(): void;
}

export const EmailHandoutsForm: React.FC<Props> = ({
  values,
  handleOnChange,
  handleOnBlur,
  errors,
  touched,
  setValues,
}) => {
  const { senderEmail } = useUserInfo();

  const { t } = useTranslation('handouts');

  const { to, cc, confirmCC, bcc, confirmBCC, confirmTo, ccCheckbox, bccCheckbox, subject, emailNote } =
    values;

  const handleCCPress = () => {
    setValues({ ...values, ccCheckbox: !ccCheckbox });
  };

  const handleBCCPress = () => {
    setValues({ ...values, bccCheckbox: !bccCheckbox });
  };

  return (
    <View>
      <InputItem
        label={t('sendingFrom')}
        value={senderEmail}
        containerStyle={styles.marginBottom}
        readOnly
        testID="input-email-from"
      />
      <View>
        <View style={styles.togglers}>
          <TogglerButton text={t('cc')} isActive={ccCheckbox} onPress={handleCCPress} />
          <TogglerButton text={t('bcc')} isActive={bccCheckbox} onPress={handleBCCPress} />
        </View>
        <View style={styles.emailToInputWrapper}>
          <InputItem
            label={t('emailTo')}
            onChangeText={(value) => handleOnChange('to', value)}
            value={to}
            error={errors.to}
            touched={!!touched.to}
            containerStyle={styles.marginBottom}
            onBlur={() => handleOnBlur('to')}
            inputMode="email"
            testID="input-email-to"
          />
        </View>
        <InputItem
          label={t('confirmEmailTo')}
          onChangeText={(value) => handleOnChange('confirmTo', value)}
          value={confirmTo}
          error={errors.confirmTo}
          touched={!!touched.confirmTo}
          containerStyle={styles.marginBottom}
          onBlur={() => handleOnBlur('confirmTo')}
          inputMode="email"
          testID="input-email-confirm-to"
        />
      </View>
      {ccCheckbox && (
        <>
          <InputItem
            label={t('cc')}
            onChangeText={(value) => handleOnChange('cc', value)}
            value={cc}
            error={errors.cc}
            touched={!!touched.cc}
            containerStyle={styles.marginBottom}
            onBlur={() => handleOnBlur('cc')}
            inputMode="email"
          />
          <InputItem
            label={t('confirmCC')}
            onChangeText={(value) => handleOnChange('confirmCC', value)}
            value={confirmCC}
            error={errors.confirmCC}
            touched={!!touched.confirmCC}
            containerStyle={styles.marginBottom}
            onBlur={() => handleOnBlur('confirmCC')}
            inputMode="email"
            readOnly={!values.cc}
          />
        </>
      )}
      {bccCheckbox && (
        <>
          <InputItem
            label={t('bcc')}
            onChangeText={(value) => handleOnChange('bcc', value)}
            value={bcc}
            error={errors.bcc}
            touched={!!touched.bcc}
            containerStyle={styles.marginBottom}
            onBlur={() => handleOnBlur('bcc')}
            inputMode="email"
          />
          <InputItem
            label={t('confirmBCC')}
            onChangeText={(value) => handleOnChange('confirmBCC', value)}
            value={confirmBCC}
            error={errors.confirmBCC}
            touched={!!touched.confirmBCC}
            containerStyle={styles.marginBottom}
            onBlur={() => handleOnBlur('confirmBCC')}
            inputMode="email"
            readOnly={!values.bcc}
          />
        </>
      )}
      <InputItem
        label={t('subject')}
        value={subject}
        containerStyle={styles.marginBottom}
        error={errors.subject}
        touched={!!touched.subject}
        onBlur={() => handleOnBlur('subject')}
        onChangeText={(value) => handleOnChange('subject', value)}
        testID="input-email-subject"
      />
      <TextArea
        onChangeText={(text: string) => handleOnChange('emailNote', text)}
        value={emailNote}
        label={t('emailMessage')}
        containerStyle={styles.marginBottom}
        testID="input-email-content"
      />
    </View>
  );
};

const TogglerButton: React.FC<TogglerButtonProps> = ({ onPress, text, isActive }) => {
  const { primary } = useTheme();
  return (
    <Pressable onPress={onPress}>
      <View style={styles.togglerWrapper}>
        <StyledText style={[typography.body3Bold, styles.togglerButtonText]}>{text}</StyledText>
        <View
          style={[{ borderColor: primary }, styles.togglerButton, isActive && { backgroundColor: primary }]}
        >
          <Icon
            name="close"
            width={10}
            color={isActive ? palette.white : primary}
            style={[!isActive && styles.togglerButtonIcon]}
          />
        </View>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  marginBottom: {
    marginBottom: 20,
  },
  togglers: {
    flexDirection: 'row',
    position: 'absolute',
    right: 0,
    top: 10,
    zIndex: 1,
  },
  togglerWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 15,
  },
  togglerButton: {
    width: 28,
    height: 28,
    borderRadius: 14,
    borderWidth: 2,
    backgroundColor: palette.transparent,
    justifyContent: 'center',
    alignItems: 'center',
  },
  togglerButtonIcon: {
    transform: [
      {
        rotate: '45deg',
      },
    ],
  },
  togglerButtonText: {
    color: palette.navy,
    marginRight: 8,
  },
  emailToInputWrapper: {
    paddingTop: 20,
  },
});
