import React, { useEffect } from 'react';

import { ContentListScreen } from 'src/components';
import { useUserInfo } from 'src/features/auth/hooks';
import { ContentListType } from 'src/features/content/constants';
import { DrugsListScreen as DrugsListScreenOld } from 'src/features/drugs/screens/DrugsListScreen.old';
import { SearchContentTypeFilter } from 'src/features/search/enums';
import { OlyticsService } from 'src/features/tracking';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';

import { DrugCard } from '../components';

type Props = ScreenProps<Route.DrugsList>;

export const DrugsListScreenNew: React.FC<Props> = ({ route, navigation }) => {
  useEffect(() => {
    OlyticsService.fire();
  }, []);

  return (
    <ContentListScreen
      title={i18n.t('drugs:drugMonographs')}
      route={route}
      navigation={navigation}
      ContentCardComponent={DrugCard}
      searchFilter={SearchContentTypeFilter.Drug}
      contentListType={ContentListType.Drugs}
    />
  );
};

export const DrugsListScreen: React.FC<Props> = (props) => {
  const {
    permissions: { hasAccessToStandards },
  } = useUserInfo();
  const Component = hasAccessToStandards ? DrugsListScreenNew : DrugsListScreenOld;

  return <Component {...props} />;
};
