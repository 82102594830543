import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { useTheme } from 'src/features/auth/hooks/useTheme';
import { ifWeb, palette } from 'src/styles';

import { Icon } from './Icon/Icon';
import { Popover } from './Popovers/Popover';
import { Pressable } from './Pressable';

type PressableProps = Pick<React.ComponentProps<typeof Pressable>, 'onPress' | 'disabled' | 'hitSlop'>;

interface Props extends React.ComponentProps<typeof Icon>, PressableProps {
  outline?: boolean;
  backgroundColor?: string;
  backgroundHoverColor?: string;
  containerSize?: number;
  testID?: string;
  containerStyle?: StyleProp<ViewStyle>;
  tooltip?: React.ReactNode | null;
  iconStyle?: StyleProp<ViewStyle>;
}

export const CircleIconButton: React.FC<Props> = ({
  outline,
  backgroundColor,
  backgroundHoverColor = palette.navy,
  containerSize,
  containerStyle,
  onPress,
  disabled,
  name,
  width,
  testID,
  tooltip,
  iconStyle,
  hitSlop,
  ...iconProps
}) => {
  const { primary } = useTheme();
  const assignedBackgroundColor = backgroundColor || primary;
  const content = (
    <Pressable onPress={onPress} disabled={disabled} testID={testID} hitSlop={hitSlop}>
      {(hovered) => (
        <View
          style={[
            styles.wrapper,
            !!containerSize && {
              width: containerSize,
              height: containerSize,
              borderRadius: containerSize / 2,
            },
            outline
              ? [
                  styles.wrapperOutline,
                  { borderColor: hovered ? backgroundHoverColor : assignedBackgroundColor },
                  disabled && { borderColor: palette.grey4 },
                ]
              : [
                  { backgroundColor: hovered ? backgroundHoverColor : assignedBackgroundColor },
                  disabled && { backgroundColor: palette.grey4 },
                ],
            containerStyle,
          ]}
        >
          <Icon
            color={
              outline
                ? disabled
                  ? palette.grey4
                  : hovered
                  ? backgroundHoverColor
                  : backgroundColor
                : palette.white
            }
            width={width || 16}
            name={name}
            style={iconStyle}
            {...iconProps}
          />
        </View>
      )}
    </Pressable>
  );

  return tooltip ? (
    <Popover content={tooltip} openOnHover>
      {content}
    </Popover>
  ) : (
    content
  );
};

const styles = StyleSheet.create({
  wrapper: {
    width: 32,
    height: 32,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    ...ifWeb({
      userSelect: 'none',
      transitionProperty: 'border-color, background-color',
    }),
  },
  wrapperOutline: {
    borderWidth: 2,
  },
});
