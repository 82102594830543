import React from 'react';
import { StyleSheet, View, StyleProp, ViewStyle } from 'react-native';

import { Icon } from 'src/components/Icon/Icon';
import { StyledText } from 'src/components/StyledText';
import { IconName } from 'src/constants/types';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { typography } from 'src/styles';

type Props = {
  title: string;
  spreader?: boolean;
  icon?: IconName;
  wrapperStyle?: StyleProp<ViewStyle>;
};

export const Subheader: React.FC<Props> = ({ title, spreader, icon, wrapperStyle }) => {
  const { primary } = useTheme();
  return (
    <View style={[styles.row, spreader && styles.additionalPadding, styles.container, wrapperStyle]}>
      <StyledText
        style={[typography.body2Bold, styles.subHeaderTitle, styles.subHeader, { color: primary }]}
        headerLevel={3}
      >
        {title}
      </StyledText>
      {!!icon && <Icon name={icon} />}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 16,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  subHeaderTitle: {
    marginRight: 10,
  },
  subHeader: {
    maxWidth: '92%',
  },
  additionalPadding: {
    marginTop: 20,
  },
});
