import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { Trans } from 'react-i18next';
import { FlatList, StyleSheet, View } from 'react-native';

import {
  AccordionLegacy,
  LoadingIndicator,
  MobileContentHeader,
  NumberIndicator,
  StyledText,
} from 'src/components';
import { AccordionRef } from 'src/constants/types/accordion';
import { InternetConnectionErrorBanner } from 'src/errorHandling/components';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { MixpanelEvent, getMixpanelDICLocation } from 'src/features/tracking';
import { MixpanelService } from 'src/features/tracking/services';
import { isWeb, markDownToPlainText } from 'src/helpers';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { useNavigation } from 'src/navigation/hooks';
import { CONTAINER_PADDING_H_MOBILE, typography } from 'src/styles';

import { InteractionsList } from './InteractionsList';
import { InteractionsPaginatedList } from './InteractionsPaginatedList';
import { useInteractionsList } from '../../hooks';
import type { DICMode, DICSource } from '../../types';
import { DICLegendLabel } from '../DICLegendLabel';

interface Props {
  drugs: string[];
  all?: boolean;
  mode?: DICMode;
  source?: DICSource;
  Header?: React.ReactElement<any>;
  Footer?: React.ReactElement<any>;
}

export interface InteractionResultsRef {
  scrollToTop(): void;
}

export const InteractionResults = forwardRef<InteractionResultsRef, Props>(
  ({ drugs, all, mode, Header, Footer, source }, ref) => {
    const { primary } = useTheme();
    const {
      isFetching,
      isError,
      classifications,
      singleDrugName,
      shouldUsePaginatedList,
      noInteractionsToDisplay,
      isOfflineError,
    } = useInteractionsList({
      drugs,
      mode,
      all,
    });
    const navigation = useNavigation();
    const accordionRef = useRef<AccordionRef>(null);
    const listRef = useRef<FlatList>(null);
    const { isTablet } = useDeviceInfo();

    useImperativeHandle(ref, () => ({
      scrollToTop: () => {
        listRef.current?.scrollToOffset({ offset: 0 });
      },
    }));

    useEffect(() => {
      if (!shouldUsePaginatedList) {
        accordionRef.current?.openAllItems();
      } else {
        accordionRef.current?.collapseAllItems();
      }
    }, [shouldUsePaginatedList, classifications.length]);

    const textStyle = [styles.sectionDescrption, isTablet && styles.sectionDescriptionTablet];

    const seeAllInteractions = () => {
      MixpanelService.track(MixpanelEvent.DICShowAllInteractions, {
        'Drug Name': singleDrugName!,
        Location: getMixpanelDICLocation(source),
      });

      navigation.navigate({
        name: Route.DIC,
        params: {
          all: true,
        },
        merge: true,
      });
    };

    return (
      <>
        {isOfflineError ? (
          <InternetConnectionErrorBanner />
        ) : (
          <AccordionLegacy
            asFlatList={!isWeb}
            ref={accordionRef}
            listRef={listRef}
            fullWidthMode={!isTablet}
            contentContainerStyle={!isWeb && styles.contentNative}
            showsVerticalScrollIndicator={false}
            testID="interaction-results-box"
            items={classifications.map(({ classificationKey, count, data }) => ({
              Content: data ? (
                <InteractionsList data={data} />
              ) : (
                <InteractionsPaginatedList classification={classificationKey} drugs={drugs} mode={mode} />
              ),
              id: classificationKey,
              Label: (
                <View style={styles.labelWrapper}>
                  <DICLegendLabel type={classificationKey} />
                  <NumberIndicator
                    text={count}
                    style={styles.numberIndicator}
                    testID="interactions-number"
                  />
                </View>
              ),
            }))}
            ListHeaderComponent={
              <>
                {Header}
                <MobileContentHeader style={[styles.sectionTitle, isTablet && styles.sectionTitleTablet]}>
                  {i18n.t('dic:interactionResults')}
                </MobileContentHeader>
                {!drugs.length && (
                  <StyledText style={textStyle}>{i18n.t('dic:interactionsNoDrugsSelected')}</StyledText>
                )}
                {singleDrugName && (
                  <Trans
                    i18nKey="dic:seeInteractionsForDrug"
                    components={{
                      p: <StyledText style={textStyle} />,
                      button: (
                        <StyledText
                          onPress={seeAllInteractions}
                          style={[{ color: primary }, styles.singleDrugButton]}
                          testID="show-all-interactions-btn"
                        />
                      ),
                    }}
                    values={{ name: markDownToPlainText(singleDrugName) }}
                  />
                )}
                {isFetching && <LoadingIndicator style={styles.loadingIndicator} displayStandbyText />}
                {!isError && noInteractionsToDisplay && (
                  <StyledText style={textStyle}>{i18n.t('dic:noInteractionsForThisDrug')}</StyledText>
                )}
                {isError && <StyledText style={textStyle}>{i18n.t('notifications:error')}</StyledText>}
              </>
            }
            ListFooterComponent={Footer}
          />
        )}
      </>
    );
  },
);

const styles = StyleSheet.create({
  loadingIndicator: {
    marginVertical: 30,
  },
  contentNative: {
    paddingVertical: 25,
  },
  labelWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  numberIndicator: {
    marginLeft: 8,
  },
  sectionTitle: {
    marginBottom: 12,
    paddingHorizontal: CONTAINER_PADDING_H_MOBILE,
  },
  sectionTitleTablet: {
    paddingHorizontal: 0,
  },
  sectionDescrption: {
    paddingHorizontal: CONTAINER_PADDING_H_MOBILE,
    ...typography.body2,
  },
  sectionDescriptionTablet: {
    paddingHorizontal: 0,
  },
  singleDrugButton: {
    ...typography.body2Bold,
    textDecorationLine: 'underline',
  },
});
