import * as R from 'ramda';
import { useState, Suspense, ReactNode } from 'react';
import { StyleSheet, useWindowDimensions, View } from 'react-native';

import { BottomBar as BottomBarOld } from 'src/components/BottomBar.old';
import { BottomStickyElementsPortal } from 'src/contexts/portals';
import { useUserInfo } from 'src/features/auth/hooks';
import { Calculator } from 'src/features/calculator';
import { isWeb } from 'src/helpers';
import { useDeviceInfo } from 'src/hooks';
import { i18n } from 'src/locale';
import { BOTTOM_BAR_HEIGHT, CONTAINER_PADDING_H_MOBILE, getShadow, ifWeb, palette } from 'src/styles';

import { AccordionsToggle } from './AccordionsToggle';
import { Container } from './Container';
import { IconTextButton } from './IconTextButton';
import { LoadingIndicator } from './LoadingIndicator';

interface ButtonWithCondition {
  condition: boolean;
  content: ReactNode;
}

interface Props {
  scrollToTop?: () => void;
  showScrollToTop?: boolean;
  toggleItemCollapse?: () => void;
  isAnyItemExpanded?: boolean;
  showCalculator?: boolean;
  /** Called after scrolling to top; not the scrolling logic itself */
  afterScrollToTop?(): void;
}

export const BottomBarNew: React.FC<Props> = ({
  toggleItemCollapse,
  isAnyItemExpanded,
  showScrollToTop,
  showCalculator,
  scrollToTop: alternativeScrollToTop,
  afterScrollToTop,
}) => {
  const scrollToTop = () => {
    if (alternativeScrollToTop) {
      alternativeScrollToTop();
    } else if (isWeb) {
      window.scrollTo({ top: 0 });
    }
    afterScrollToTop?.();
  };

  const { isHugeDesktop, isLargeDesktop } = useDeviceInfo();
  const [isCalculatorOpen, setCalculatorOpen] = useState(false);
  const isCalculatorAvailable = isCalculatorOpen || showCalculator;

  const {
    permissions: { hasAccessToStandards },
  } = useUserInfo();

  const { height: screenHeight } = useWindowDimensions();
  const expandedFooterHeight = R.clamp(450, 480, screenHeight * 0.5);

  const possibleLeftButtons: ButtonWithCondition[] = [
    {
      condition: !!isCalculatorAvailable,
      content: (
        <IconTextButton
          text={i18n.t(`calculator:calculator`)}
          onPress={() => setCalculatorOpen(!isCalculatorOpen)}
          icon={isCalculatorOpen ? 'close' : 'chevron-up'}
          style={[isCalculatorOpen && styles.calculatorTextButton]}
          iconPosition="right"
          circleIcon
          testID="button-calculator"
        />
      ),
    },
    {
      condition: !!toggleItemCollapse,
      content: (
        <AccordionsToggle
          openTestID="button-open-all"
          closeTestID="button-close-all"
          onPress={toggleItemCollapse}
          isAnyItemExpanded={isAnyItemExpanded}
        />
      ),
    },
  ];

  const leftButton = possibleLeftButtons.find((item) => item.condition)?.content;

  const content = (
    <View
      style={[
        styles.footer,
        isCalculatorOpen && { height: expandedFooterHeight },
        isLargeDesktop && styles.footerRow,
      ]}
    >
      <View
        style={[
          styles.footerButtons,
          !isCalculatorOpen && styles.footerButtonsFull,
          hasAccessToStandards
            ? isHugeDesktop
              ? styles.footerWrapperHugeStandards
              : styles.footerWrapperMassiveStandards
            : isHugeDesktop
            ? styles.footerWrapperHuge
            : styles.footerWrapperMassive,
        ]}
      >
        {!isLargeDesktop && leftButton}
        {!isCalculatorOpen && <View style={styles.placeholder}></View>}
        {!isCalculatorOpen && showScrollToTop && (
          <IconTextButton
            text={i18n.t(`common:backToTop`)}
            onPress={scrollToTop}
            icon="arrow-up"
            iconPosition="right"
            circleIcon
            iconWidth={12}
          />
        )}
      </View>
      {isCalculatorAvailable && (
        <Container style={[styles.calculatorWrapper, isCalculatorOpen && styles.calculatorWrapperOpen]}>
          <Suspense fallback={<LoadingIndicator />}>
            <Calculator inPageView />
          </Suspense>
        </Container>
      )}
    </View>
  );

  if (isWeb) {
    return <BottomStickyElementsPortal>{content}</BottomStickyElementsPortal>;
  }
  return content;
};

const styles = StyleSheet.create({
  footer: {
    height: BOTTOM_BAR_HEIGHT,
    justifyContent: 'center',
    backgroundColor: palette.white,
    ...getShadow(4, 0.48, 20),
    ...ifWeb(
      {},
      {
        borderTopWidth: 1,
        borderTopColor: palette.grey2,
      },
    ),
  },
  placeholder: {
    flex: 1,
  },
  footerRow: {
    flexDirection: 'row',
  },
  footerWrapperHuge: {
    maxWidth: 778,
  },
  footerWrapperMassive: {
    maxWidth: 1082,
  },
  footerWrapperHugeStandards: {
    maxWidth: 814,
  },
  footerWrapperMassiveStandards: {
    maxWidth: 1114,
  },
  footerButtons: {
    flexDirection: 'row',
    paddingHorizontal: CONTAINER_PADDING_H_MOBILE,
    paddingVertical: 11,
  },
  footerButtonsFull: {
    flex: 1,
  },
  calculatorWrapper: {
    paddingBottom: 16,
    maxWidth: 400,
    flex: 1,
    display: 'none',
  },
  calculatorWrapperOpen: {
    display: 'flex',
  },
  calculatorTextButton: {
    flex: 1,
    justifyContent: 'space-between',
  },
});

export const BottomBar: React.FC<Props> = (props) => {
  const {
    permissions: { hasFullAccessToCoreFuncUXUpdates },
  } = useUserInfo();
  const Component = hasFullAccessToCoreFuncUXUpdates ? BottomBarNew : BottomBarOld;

  return <Component {...props} />;
};
