import { useTheme } from 'src/features/auth/hooks/useTheme';
import { palette } from 'src/styles';

const GRADIENT_ID = 'loading-indicator-gradient';

interface Props {
  size: number;
  color?: string;
}

export const Spinner: React.FC<Props> = ({ size, color }) => {
  const { primary } = useTheme();
  return (
    <svg
      version="1.1"
      viewBox="0 0 40 40"
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="20"
        cy="20"
        r="18"
        strokeWidth="2.6"
        fill="none"
        stroke={color ?? `url(#${GRADIENT_ID})`}
        strokeDasharray="100"
        strokeDashoffset="20"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 20 20"
          to="360 20 20"
          dur="900ms"
          repeatCount="indefinite"
        />
      </circle>
      <defs>
        <linearGradient
          id={GRADIENT_ID}
          x1="20.5"
          y1="1.29688"
          x2="39.2"
          y2="19.9969"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={primary} />
          <stop stopColor={palette.brightBlue} offset="1" />
        </linearGradient>
      </defs>
    </svg>
  );
};
