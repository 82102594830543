import React from 'react';
import { StyleSheet, View } from 'react-native';

import type { IconName } from 'src/constants/types';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { Link } from 'src/navigation/components';
import { palette, typography } from 'src/styles';

import { PrimaryButton, SecondaryButton, FlatButton } from '.';
import { BaseButton } from './Buttons/BaseButton';
import { Icon } from './Icon/Icon';
import { Pressable } from './Pressable';
import { StyledText } from './StyledText';

interface Body extends React.PropsWithChildren {
  icon?: IconName;
  header?: string;
  iconWidth?: number;
  iconHeight?: number;
  strokeWidth?: number;
}

interface Content extends React.ComponentProps<typeof StyledText> {
  small?: boolean;
}

interface Buttons extends React.ComponentProps<typeof View> {
  direction?: 'row' | 'column';
}

interface ModalButton extends React.ComponentProps<typeof BaseButton> {
  linkProps?: React.ComponentProps<typeof Link>;
}

interface TextButton extends React.ComponentProps<typeof Pressable> {
  title: string;
}

export const Body: React.FC<Body> = ({
  icon,
  header,
  iconWidth = 53,
  iconHeight,
  strokeWidth,
  children,
}) => {
  return (
    <View style={styles.wrapper}>
      {icon && (
        <Icon
          name={icon}
          width={iconWidth}
          height={iconHeight}
          style={styles.icon}
          strokeWidth={strokeWidth}
          color="plumbsGradient"
        />
      )}
      {!!header && (
        <StyledText style={styles.header} testID="modal-header">
          {header}
        </StyledText>
      )}
      {children}
    </View>
  );
};

export const Content: React.FC<Content> = ({ style, small, ...rest }) => (
  <StyledText style={[styles.content, small && styles.contentSmall, style]} {...rest} />
);

export const Buttons: React.FC<Buttons> = ({ style, direction, ...rest }) => {
  const { isTablet } = useDeviceInfo();

  return (
    <View
      style={[styles.buttonsWrapper, direction === 'row' && isTablet && styles.buttonsWrapperRow, style]}
      {...rest}
    />
  );
};

export const ModalButton: React.FC<ModalButton> = ({ linkProps, containerStyle, ...rest }) => {
  const { variant } = rest;
  const ButtonComponent =
    variant === 'secondary' ? SecondaryButton : variant === 'flat' ? FlatButton : PrimaryButton;

  const button =
    variant === 'gradient' ? (
      <BaseButton
        size="medium"
        containerStyle={linkProps ? undefined : [containerStyle, styles.button]}
        {...rest}
        variant="gradient"
      />
    ) : (
      <ButtonComponent
        size="medium"
        containerStyle={linkProps ? undefined : [containerStyle, styles.button]}
        {...rest}
      />
    );

  return linkProps ? (
    <Link style={[styles.button, containerStyle]} {...linkProps}>
      {button}
    </Link>
  ) : (
    button
  );
};

export const TextButton: React.FC<TextButton> = ({ title, onPress, testID, ...rest }) => {
  const { primary } = useTheme();
  return (
    <Pressable onPress={onPress} {...rest}>
      <StyledText style={[styles.textButton, { color: primary }]} testID={testID}>
        {title}
      </StyledText>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    alignItems: 'center',
    width: '100%',
  },
  icon: {
    marginBottom: 20,
  },
  header: {
    color: palette.navy,
    textAlign: 'center',
    marginBottom: 16,
    ...typography.heading3,
  },
  content: {
    ...typography.body3,
    textAlign: 'center',
    marginBottom: 20,
  },
  contentSmall: {
    ...typography.body2,
  },
  buttonsWrapper: {
    alignSelf: 'stretch',
    marginTop: 8,
    paddingHorizontal: -4,
  },
  buttonsWrapperRow: {
    justifyContent: 'center',
    flexDirection: 'row',
  },
  button: {
    margin: 4,
    width: '98%',
    flexShrink: 1,
  },
  textButton: {
    ...typography.body1SemiBold,
    alignSelf: 'center',
    cursor: 'pointer',
    paddingTop: 16,
  },
});
