import { memo, useState } from 'react';
import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from 'react-native';

import { ListItem as ListItemOld } from 'src/components/ListItem.old';
import { useUserInfo } from 'src/features/auth/hooks';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { Link } from 'src/navigation/components';
import { CONTAINER_PADDING_H_MOBILE, ifWeb, palette, typography } from 'src/styles';

import { MarkdownContent } from './MarkdownContent/MarkdownContent';
import { Pressable } from './Pressable';
import { StyledText } from './StyledText';

interface Props {
  title: string;
  linkProps?: React.ComponentProps<typeof Link>;
  onPress?(): void;
  rightColumn?: React.ReactNode;
  topBorder?: boolean;
  bottomBorder?: boolean;
  sideBorder?: boolean;
  roundedTopBorder?: boolean;
  roundedBottomBorder?: boolean;
  style?: StyleProp<ViewStyle>;
  textHoveredStyle?: StyleProp<TextStyle>;
  textStyle?: StyleProp<TextStyle>;
  testID?: string;
  isNew?: boolean;
  additionalDescription?: string;
  favoriteItem?: boolean;
  sideBar?: boolean;
}

export const ListItemNew = memo<Props>(
  ({
    title,
    linkProps,
    onPress,
    rightColumn,
    topBorder,
    bottomBorder = true,
    sideBorder,
    roundedTopBorder,
    roundedBottomBorder,
    style,
    textHoveredStyle,
    textStyle,
    testID,
    isNew,
    additionalDescription,
    favoriteItem,
    sideBar,
  }) => {
    const { primary } = useTheme();
    const [isHovered, setIsHovered] = useState(false);
    const { isPro } = useUserInfo();

    const content = (
      <View
        accessibilityLabel={title}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={[
          styles.wrapper,
          sideBar && isPro ? styles.wrapperDark : styles.wrapperLight,
          bottomBorder && styles.wrapperBottomBorder,
          topBorder && styles.wrapperTopBorder,
          sideBorder && !sideBar && styles.wrapperSideBorder,
          roundedTopBorder && styles.roundedTopBorder,
          roundedBottomBorder && styles.roundedBottomBorder,
          style,
        ]}
        testID={testID}
      >
        <View style={[styles.titleWrapper, styles.flexWrapper]}>
          {isNew && <View style={[{ backgroundColor: primary }, styles.newContentIndicator]} />}
          <View style={styles.textWrapper}>
            <MarkdownContent
              style={[
                typography.body3SemiBold,
                styles.title,
                favoriteItem && isPro && sideBar ? styles.white : styles.navy,
                textStyle,
                isHovered && !isPro && textHoveredStyle,
              ]}
              inline
              limited
              headerLevel={3}
            >
              {title}
            </MarkdownContent>
            {!!additionalDescription && (
              <StyledText style={styles.description} testID="spanish-info">
                {additionalDescription}
              </StyledText>
            )}
          </View>
        </View>
        {rightColumn}
      </View>
    );

    return linkProps ? (
      <Link {...linkProps} onPress={onPress || linkProps.onPress}>
        {content}
      </Link>
    ) : onPress ? (
      <Pressable onPress={onPress}>{content}</Pressable>
    ) : (
      content
    );
  },
);

const styles = StyleSheet.create({
  wrapper: {
    paddingVertical: 12,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: CONTAINER_PADDING_H_MOBILE,
    transition: 'all 250ms',
  },
  wrapperLight: {
    borderColor: palette.grey2,
  },
  wrapperDark: {
    borderColor: palette.grey7,
  },
  wrapperBottomBorder: {
    borderBottomWidth: 1,
  },
  wrapperTopBorder: {
    borderTopWidth: 1,
  },
  wrapperSideBorder: {
    borderLeftWidth: 1,
    borderRightWidth: 1,
  },
  roundedTopBorder: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  roundedBottomBorder: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  textWrapper: {
    flexDirection: 'row',
    alignItems: 'baseline',
    flexWrap: 'wrap',
    flex: 1,
  },
  title: {
    flexShrink: 1,
    marginRight: 10,
    lineHeight: 20,
    color: palette.navy,
    ...ifWeb({
      wordBreak: 'break-word',
      transitionProperty: 'color',
    }),
  },
  navy: {
    color: palette.navy,
  },
  white: {
    color: palette.white,
  },
  titleWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  flexWrapper: {
    flex: 1,
  },
  newContentIndicator: {
    width: 10,
    height: 10,
    borderRadius: 5,
    marginRight: 8,
  },
  description: {
    lineHeight: 20,
  },
});

export const ListItem: React.FC<Props> = (props) => {
  const {
    permissions: { hasAccessToStandards },
  } = useUserInfo();
  const Component = hasAccessToStandards ? ListItemNew : ListItemOld;

  return <Component {...props} />;
};
