import { View, StyleSheet, ScrollView } from 'react-native';

import { Icon, LoadingIndicator, Pressable, StyledText } from 'src/components';
import { StatusBadge } from 'src/components/StatusBadge';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { ContentStatus } from 'src/features/content/constants';
import { useUpdateUserSeenFlows } from 'src/features/launchpad/hooks';
import { AppcuesService } from 'src/features/tracking';
import { palette, typography } from 'src/styles';

import { AppcuesLaunchpadContent } from '../types';

interface Props {
  data: AppcuesLaunchpadContent[];
  isLoading: boolean;
  isLaunchpadListScrollable: boolean;
}

export const LaunchpadList: React.FC<Props> = ({ data, isLoading, isLaunchpadListScrollable }) => {
  const { primary } = useTheme();
  const { mutate: updateUserSeenFlows } = useUpdateUserSeenFlows();
  const onItemPress = (id: string, isNew: boolean) => {
    AppcuesService.displayFlow(id);
    if (isNew) {
      updateUserSeenFlows(id);
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingIndicator size="small" style={styles.loadingIndicator} />
      ) : (
        <ScrollView style={styles.menuWrapper} showsVerticalScrollIndicator={false}>
          {data?.map(({ id, name, isNew }, index) => (
            <Pressable onPress={() => onItemPress(id, isNew)} key={id}>
              {(isHovered) => (
                <View
                  style={[
                    styles.menuItem,
                    index !== 0 && styles.menuItemTopBorder,
                    index === data.length - 1 && !isLaunchpadListScrollable && styles.menuItemBottomBorder,
                    styles.menuItemAndIconWrapper,
                  ]}
                >
                  <StyledText style={[styles.menuItemText, isHovered && { color: primary }]}>
                    {name}
                  </StyledText>
                  <View style={styles.menuItemRightColumn}>
                    {isNew && (
                      <StatusBadge
                        statusType={ContentStatus.New}
                        textColor={primary}
                        style={styles.newBadge}
                      />
                    )}
                    <Icon name="chevron-right" color={palette.navy} width={9} />
                  </View>
                </View>
              )}
            </Pressable>
          ))}
        </ScrollView>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  menuWrapper: {
    maxHeight: 254,
  },
  menuItem: {
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderColor: palette.grey2,
  },
  menuItemTopBorder: {
    borderTopWidth: 1,
  },
  menuItemBottomBorder: {
    borderBottomWidth: 1,
  },
  menuItemAndIconWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  menuItemText: {
    ...typography.body3,
    color: palette.navy,
    marginRight: 8,
    transitionProperty: 'color',
  },
  menuItemRightColumn: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  newBadge: {
    marginRight: 12,
  },
  loadingIndicator: {
    flex: 1,
    paddingTop: 12,
    justifyContent: 'flex-end',
  },
});
