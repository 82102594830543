import React from 'react';
import { StyleSheet, TextStyle, View, ViewStyle } from 'react-native';

import { Icon, Pressable, StyledText } from 'src/components';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { Link } from 'src/navigation/components';
import { NavigationMenuItem } from 'src/navigation/types';
import { ifWeb, palette, typography } from 'src/styles';

interface Props extends NavigationMenuItem {
  closeDropdown?: () => void;
  isSubmenuOpen: boolean;
  isLastItem: boolean;
  toggleSubmenu: () => void;
}

export const NavigationDropdownItem: React.FC<Props> = ({
  text,
  to,
  external,
  onPress,
  closeDropdown,
  isSubmenuOpen,
  toggleSubmenu,
  isLastItem,
  disabled,
  ExtendedDropdownComponent,
  testID,
}) => {
  const { primary } = useTheme();

  const handleSubmenuTogglePress = () => {
    toggleSubmenu();
    onPress?.();
  };

  const handlePress = () => {
    onPress?.();
    closeDropdown?.();
  };

  const getItemStyles = (isHovered?: boolean) => {
    return [
      styles.item,
      isHovered && styles.itemHover,
      isLastItem && styles.itemLast,
      disabled && styles.unavailable,
    ];
  };

  const renderTextElement = (text: string, isHovered?: boolean, disabled?: boolean) => (
    <StyledText
      style={[
        typography.body2,
        styles.text,
        !disabled ? isHovered && { color: primary } : styles.unavailableText,
      ]}
    >
      {text}
    </StyledText>
  );

  return (
    <>
      {to ? (
        <Link to={to} external={external} onPress={handlePress} disabled={disabled} testID={testID}>
          {(isHovered) => (
            <View style={getItemStyles(isHovered)}>{renderTextElement(text, isHovered, disabled)}</View>
          )}
        </Link>
      ) : ExtendedDropdownComponent ? (
        <Pressable onPress={handleSubmenuTogglePress} disabled={disabled} testID={testID}>
          {(isHovered) => (
            <View style={getItemStyles(isHovered)}>
              {renderTextElement(text, isHovered, disabled)}
              <Icon
                width={10}
                color={isHovered ? primary : palette.navy}
                name="chevron-down"
                style={[styles.icon, isSubmenuOpen && styles.iconOpen]}
              />
            </View>
          )}
        </Pressable>
      ) : (
        <Pressable onPress={handlePress} disabled={disabled} testID={testID}>
          {(isHovered) => (
            <View style={getItemStyles(isHovered)}>{renderTextElement(text, isHovered, disabled)}</View>
          )}
        </Pressable>
      )}
      {!!ExtendedDropdownComponent && isSubmenuOpen && (
        <View style={styles.extendedDropdownWrapper}>
          <ExtendedDropdownComponent inMobileDropdown />
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  unavailable: {
    backgroundColor: palette.grey1,
    ...ifWeb({
      cursor: 'default',
    }),
  } as ViewStyle,
  unavailableText: {
    ...ifWeb({
      cursor: 'default',
    }),
  } as TextStyle,
  item: {
    display: 'flex',
    width: '100%',
    paddingVertical: 12,
    paddingHorizontal: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: palette.grey2,
    ...ifWeb({
      cursor: 'pointer',
      userSelect: 'none',
      transitionProperty: 'background-color',
    }),
  },
  itemHover: {
    backgroundColor: palette.grey1,
  },
  itemLast: {
    borderBottomWidth: 0,
  },
  text: {
    color: palette.navy,
    ...ifWeb({
      transitionProperty: 'color',
    }),
  },
  icon: {
    ...ifWeb({
      transitionProperty: 'transform',
    }),
  },
  iconOpen: {
    transform: [
      {
        rotate: '180deg',
      },
    ],
  },
  extendedDropdownWrapper: {
    borderBottomWidth: 1,
    borderBottomColor: palette.grey2,
  },
});
