class ThemeManager {
  static currentTheme: 'light' | 'dark' | null = null;

  static setColorScheme(theme: 'light' | 'dark' | null) {
    this.currentTheme = theme;
  }

  static getCurrentTheme() {
    return this.currentTheme;
  }
}

export default ThemeManager;
