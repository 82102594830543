import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { Tile as TileOld } from 'src/components/TilesMenu/Tile.old';
import { IconName } from 'src/constants/types';
import { useUserInfo } from 'src/features/auth/hooks';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { Link } from 'src/navigation/components';
import { getShadow, ifWeb, palette, typography } from 'src/styles';

import { GradientBar } from '../GradientBar';
import { Icon } from '../Icon/Icon';
import { StyledText } from '../StyledText';

interface Props {
  linkProps: Omit<React.ComponentProps<typeof Link>, 'children'>;
  title: string;
  description?: string;
  testID?: string;
  icon: IconName;
  small?: boolean;
  wrapperStyle?: StyleProp<ViewStyle>;
  showProBadge?: boolean;
}

export const TileNew: React.FC<Props> = ({
  linkProps,
  title,
  description,
  testID,
  small,
  wrapperStyle,
  icon,
  showProBadge,
}) => {
  const { isPro } = useUserInfo();
  const { primary } = useTheme();
  return (
    <Link {...linkProps} testID={testID} style={styles.link} wrapperStyle={[styles.wrapper, wrapperStyle]}>
      <View style={[styles.content, small && styles.contentSmall]}>
        <Icon
          name={icon}
          width={small ? 40 : 80}
          style={small ? styles.iconSmall : styles.iconBig}
          strokeWidth={small ? 1.8 : 1.4}
          color={isPro ? 'standardsGradient' : 'plumbsGradient'}
        />
        <View style={[styles.textWrapper, small && styles.textWrapperSmall]}>
          <View style={small && styles.titleWrapperSmall}>
            <View style={[styles.title, small && styles.titleSmall]}>
              <StyledText style={typography.body3Bold} headerLevel={2}>
                {title}
              </StyledText>
              {showProBadge && !isPro && (
                <View style={styles.standardsBadgeWrapper}>
                  <GradientBar gradientType="proStandardsBadgeGradient">
                    <StyledText style={styles.standardsBadge}>Standards</StyledText>
                  </GradientBar>
                </View>
              )}
            </View>
            {small && <Icon name="chevron-right" color={primary} width={10} />}
          </View>
          <StyledText
            style={[styles.description, small && styles.descriptionSmall, typography.body2]}
            headerLevel={3}
          >
            {description}
          </StyledText>
        </View>
      </View>
    </Link>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    ...getShadow(0, 0.48, 20),
    borderRadius: 5,
    overflow: 'hidden',
  },
  link: {
    height: '100%',
  },
  content: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    backgroundColor: palette.white,
    paddingTop: 40,
    paddingBottom: 20,
  },
  contentSmall: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingHorizontal: 20,
    flexDirection: 'row',
  },
  iconSmall: {
    marginRight: 10,
  },
  iconBig: {
    marginBottom: 10,
  },
  textWrapper: {
    flexGrow: 1,
    alignItems: 'center',
  },
  textWrapperSmall: {
    alignItems: 'flex-start',
    flexShrink: 1,
  },
  titleWrapperSmall: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  title: {
    alignItems: 'center',
    color: palette.navy,
    flexDirection: 'row',
    marginBottom: 8,
    textAlign: 'center',
    ...ifWeb({
      whiteSpace: 'normal',
    }),
  },
  titleSmall: {
    textAlign: 'left',
    marginBottom: 4,
  },
  description: {
    color: palette.grey5,
    textAlign: 'center',
    maxWidth: '70%',
    ...ifWeb({
      whiteSpace: 'normal',
    }),
  },
  descriptionSmall: {
    textAlign: 'left',
  },
  standardsBadgeWrapper: {
    overflow: 'hidden',
    marginLeft: 8,
    ...ifWeb(
      {
        borderRadius: 5,
      },
      {
        borderRadius: 3,
      },
    ),
  },
  standardsBadge: {
    fontSize: 12,
    fontWeight: 700,
    textTransform: 'uppercase',
    color: palette.white,
    borderRadius: 5,
    height: 20,
    paddingHorizontal: 6,
    paddingVertical: 2,
  },
});

export const Tile: React.FC<Props> = (props) => {
  const {
    permissions: { hasAccessToStandards },
  } = useUserInfo();
  const Component = hasAccessToStandards ? TileNew : TileOld;

  return <Component {...props} />;
};
