import * as R from 'ramda';
import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { Box, Form, FullScreenTemplate, InputItem, StyledText } from 'src/components';
import { getErrorMessageForApiError } from 'src/errorHandling/utils';
import { useIsSignedIn } from 'src/features/auth/hooks';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { showNotification } from 'src/helpers';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { Link } from 'src/navigation/components';
import { useNavigation } from 'src/navigation/hooks';
import { typography } from 'src/styles';

import { GroupInvitationMessage } from './GroupInvitationMessage';
import { useGroupInvitation } from '../../../../hooks/useGroupInvitation';

interface Props {
  email: string;
  isInvitedAsSupportStaff: boolean;
  groupName: string;
  memberId: string;
}

export const GroupInvitationForm: React.FC<Props> = ({
  email,
  isInvitedAsSupportStaff,
  groupName,
  memberId,
}) => {
  const { primary } = useTheme();
  const [password, setPassword] = useState('');
  const [fieldsErrors, setFieldErrors] = useState({
    email: '',
    password: '',
  });
  const isSignedIn = useIsSignedIn();
  const navigation = useNavigation();

  const onAcceptGroupInvitationSuccess = () => {
    if (isSignedIn) {
      navigation.navigate(Route.Home);
    } else {
      navigation.navigate({ name: Route.SignIn, params: { email }, merge: true });
    }
  };

  const { termsAccepted, setTerms, acceptGroupInvitation, isAcceptGroupInvitationPending } =
    useGroupInvitation({
      onSuccess: onAcceptGroupInvitationSuccess,
    });

  const { isDesktop } = useDeviceInfo();

  const validateForm = () => {
    if (!password) {
      setFieldErrors({
        email: '',
        password: i18n.t('validation:thisFieldIsRequired'),
      });
      return false;
    }
    if (!termsAccepted) {
      return false;
    }

    return true;
  };

  const acceptInvitation = async () => {
    acceptGroupInvitation(
      { email, password, membershipId: memberId },
      {
        onError: (err: any) => {
          const status = err?.response?.status;
          if (status === 400 || status === 401) {
            const errorMessages = R.mapObjIndexed(getErrorMessageForApiError, err?.response?.data);
            const passError = errorMessages.detail ? errorMessages.detail : '';
            const errors = {
              email: '',
              password: passError,
              ...errorMessages,
            };
            setFieldErrors(errors);
          } else if (status === 404) {
            showNotification({ type: 'error', title: i18n.t('groupInvitation:tokenNotValid') });
            navigation.navigate(Route.Home);
          } else {
            showNotification({ type: 'error' });
          }
        },
      },
    );
  };

  const submitForm = () => {
    const isValid = isSignedIn || validateForm();
    isValid && acceptInvitation();
  };

  return (
    <FullScreenTemplate>
      <View style={[styles.container, isDesktop ? styles.containerDesktop : styles.containerMobile]}>
        <Box narrow padded testID="group-invitation-screen">
          <Form>
            <GroupInvitationMessage
              groupName={groupName}
              invitedAsSupportStaff={isInvitedAsSupportStaff}
              isLoading={isAcceptGroupInvitationPending}
              onSubmit={submitForm}
              onTermsChange={setTerms}
              termsAccepted={termsAccepted}
            >
              {!isSignedIn && (
                <View style={styles.inputsWrapper}>
                  <InputItem
                    containerStyle={styles.input}
                    label={i18n.t('emailAddress')}
                    readOnly
                    value={email}
                    touched
                    error={fieldsErrors.email}
                    autoCompleteType="email"
                    inputMode="email"
                    testID="join-group-email-input"
                  />
                  <InputItem
                    containerStyle={styles.input}
                    label={i18n.t('password')}
                    value={password}
                    onChangeText={setPassword}
                    secureTextEntry
                    touched
                    error={fieldsErrors.password}
                    onSubmitEditing={submitForm}
                    testID="join-group-password-input"
                  />
                  <Link to={{ route: Route.ResetPassword, params: { email } }}>
                    {(isHovered) => (
                      <StyledText
                        style={[
                          typography.body2,
                          { color: primary },
                          isHovered && [{ textDecorationColor: primary }, styles.linkHover],
                        ]}
                      >
                        {i18n.t('signIn:forgotPassword')}
                      </StyledText>
                    )}
                  </Link>
                </View>
              )}
            </GroupInvitationMessage>
          </Form>
        </Box>
      </View>
    </FullScreenTemplate>
  );
};

const styles = StyleSheet.create({
  input: {
    marginBottom: 8,
  },
  linkHover: {
    textDecorationStyle: 'solid',
    textDecorationLine: 'underline',
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerMobile: {
    paddingHorizontal: '3.5%',
    paddingVertical: '6.5%',
  },
  containerDesktop: {
    padding: 60,
  },
  inputsWrapper: {
    width: '100%',
    marginBottom: 30,
  },
});
