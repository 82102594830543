import React, { useCallback, useMemo } from 'react';

import { StyledText, Table } from 'src/components';
import { TableColumn } from 'src/constants/types';
import { GroupMembersTable as GroupMembersTableOld } from 'src/features/adminDashboard/components/MyGroup/GroupMembersTable.old';
import { useUserInfo } from 'src/features/auth/hooks';
import { formatDateWithMonths } from 'src/helpers';
import { i18n } from 'src/locale';
import { typography } from 'src/styles';

import { GroupMemberActions } from './GroupMemberActions';
import { InvitationStatus } from './InvitationStatus';
import { getUserRoleTranslationKey, getMemberLicenseText } from '../../helpers';
import { GroupMember, GroupMembersComponentProps, GroupMemberStatus } from '../../types';

interface Props extends GroupMembersComponentProps {}

export const GroupMembersTableNew: React.FC<Props> = ({ data, isMixedGroup }) => {
  const renderUsernameCell = useCallback(
    (member: GroupMember) =>
      member.fullName ? (
        <StyledText style={typography.body1Short}>
          {/* eslint-disable-next-line react-native/no-raw-text */}
          <StyledText style={typography.weightSemiBold}>{member.fullName}</StyledText> ({member.email})
        </StyledText>
      ) : (
        <StyledText style={typography.body1Short}>{member.email}</StyledText>
      ),
    [],
  );

  const renderCreatedAtCell = useCallback((member: GroupMember) => {
    return member.status === GroupMemberStatus.Confirmed ? (
      <StyledText style={typography.body1Short}>{formatDateWithMonths(member.createdAt || '')}</StyledText>
    ) : (
      <InvitationStatus status={member.status} emailStatus={member.emailStatus} />
    );
  }, []);

  const getMemberRoleText = useCallback(
    (member: GroupMember) => i18n.t(getUserRoleTranslationKey(member)),
    [],
  );

  const tableColumns = useMemo(() => {
    const columns: (TableColumn<GroupMember> & { isHidden?: boolean })[] = [
      {
        key: 'email',
        header: i18n.t('adminDashboard:member'),
        sort: true,
        renderCell: renderUsernameCell,
        testID: 'group-member',
      },
      {
        key: 'createdAt',
        header: i18n.t('adminDashboard:dateCreatedOrStatus'),
        sort: true,
        renderCell: renderCreatedAtCell,
        testID: 'group-member-createdAt',
      },
      {
        key: 'role',
        header: i18n.t('adminDashboard:role'),
        sort: (a, b) => (getMemberRoleText(a) > getMemberRoleText(b) ? 1 : -1),
        renderCell: getMemberRoleText,
        testID: 'group-member-role',
      },
      {
        key: 'licence',
        header: i18n.t('adminDashboard:license'),
        renderCell: getMemberLicenseText,
        isHidden: !isMixedGroup,
        testID: 'group-member-license',
      },
      {
        key: 'actions',
        header: i18n.t('adminDashboard:actions'),
        renderCell: (member) => <GroupMemberActions member={member} inTable />,
        testID: 'group-member-actions',
      },
    ];

    return columns.filter((column) => !column.isHidden);
  }, [renderUsernameCell, renderCreatedAtCell, isMixedGroup, getMemberRoleText]);

  return (
    <Table
      data={data}
      initialSortBy="role"
      minWidth={800}
      columns={tableColumns}
      columnsWidths={[...new Array(tableColumns.length - 1).fill(''), '1%']}
    />
  );
};

export const GroupMembersTable: React.FC<Props> = (props) => {
  const {
    permissions: { hasAccessToStandards },
  } = useUserInfo();
  const Component = hasAccessToStandards ? GroupMembersTableNew : GroupMembersTableOld;

  return <Component {...props} />;
};
