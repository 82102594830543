import { ContentStyle, FlashList } from '@shopify/flash-list';
import { useState } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { LoadingIndicator, StyledText } from 'src/components';
import { QueryErrorBanner } from 'src/errorHandling/components/QueryErrorBanner';
import { isWeb } from 'src/helpers';
import { useContainer } from 'src/hooks';
import { i18n } from 'src/locale';
import { FLASH_LIST_ESTIMATED_ITEM_SIZE, ifWeb, palette, typography } from 'src/styles';

import { FavoritesCard } from './FavoritesCard';
import { FavoritesFilterDropdown } from './FavoritesFilter';
import { FavoritesFilters } from '../config/filters';
import { useFavoritesList } from '../hooks';

interface Props {
  contentContainerStyle?: ContentStyle;
  headerStyle?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
  errorWrapperStyle?: StyleProp<ViewStyle>;
  whiteText?: boolean;
  sideBar?: boolean;
}

/* 
we need to subtract some pixels (in this case we use border radius value) 
from the height to avoid the last item being cut off on web (see #4974).
In addition, FlashList need to have a wrapper with a height, in the other
case it will show a warning in the console.
*/
const FLASHLIST_HEIGHT_MULTIPLER = FLASH_LIST_ESTIMATED_ITEM_SIZE - 5;

export const FavoritesList: React.FC<Props> = ({
  headerStyle,
  contentContainerStyle,
  contentStyle,
  errorWrapperStyle,
  whiteText,
  sideBar,
}) => {
  const [filterId, setFilter] = useState<string>(FavoritesFilters.defaultFilter.id);

  const { data, isLoading, isSuccess, error, fetchStatus } = useFavoritesList(
    FavoritesFilters.getFilterById(filterId),
  );
  const containerStyle = useContainer({ fullWidthOnMobile: true });
  const titleStyle = useContainer();

  const noItems = isSuccess && !data.length;
  const isDefaultFilter = filterId === FavoritesFilters.defaultFilter.id;

  return (
    <>
      <View style={[styles.header, headerStyle]}>
        <FavoritesFilterDropdown
          selectedFilterId={filterId}
          setFilter={setFilter}
          disabled={isDefaultFilter && (isLoading || !data?.length)}
        />
      </View>
      <View style={[styles.contentWrapper, contentStyle]} testID="favorites-list">
        {isLoading && <LoadingIndicator style={styles.loadingIndicator} displayStandbyText />}
        <QueryErrorBanner
          error={error}
          fetchStatus={fetchStatus}
          isDataAvailable={!!data}
          errorComponentType="message"
          centered={false}
          messageTextStyle={whiteText && styles.whiteText}
          style={errorWrapperStyle}
        />
        {noItems && (
          <StyledText
            style={[styles.noFavoritesMessage, whiteText && styles.whiteText, !isWeb && titleStyle]}
            testID="no-favorite-items-message"
          >
            {i18n.t('favorites:noFavoritesMessage')}
          </StyledText>
        )}
        {!!data?.length && !error && (
          <View style={[!isWeb && containerStyle, { minHeight: data.length * FLASHLIST_HEIGHT_MULTIPLER }]}>
            <FlashList
              data={data}
              renderItem={({ item, index }) => (
                <FavoritesCard
                  {...item}
                  first={index === 0}
                  last={index === data.length - 1}
                  sideBar={sideBar}
                />
              )}
              keyExtractor={(item) => item.contentId}
              contentContainerStyle={contentContainerStyle}
              estimatedItemSize={FLASH_LIST_ESTIMATED_ITEM_SIZE}
            />
          </View>
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  header: {
    marginBottom: 9,
    zIndex: 1,
    alignItems: 'flex-start',
  },
  loadingIndicator: {
    paddingVertical: 40,
  },
  noFavoritesMessage: {
    ...typography.body2,
  },
  contentWrapper: {
    ...ifWeb(
      {},
      {
        flex: 1,
      },
    ),
  },
  whiteText: {
    color: palette.white,
  },
});
