export const palette = {
  underlay: 'rgba(0, 0, 0, 0.3)',
  shadow: '#17285E',
  opaqueBlack: 'rgba(0, 0, 0, 0.8)',
  drawerOverlay: 'rgba(255, 255, 255, 0.4)',
  semiTransparent: 'rgba(0, 0, 0, 0.5)',
  blueScrim: 'rgba(23, 40, 94, 0.8)',
  whiteTransparent: 'rgba(255, 255, 255, 0.5)',
  hoverOverlay: 'rgba(0,0,0,0.44)',
  transparent: 'transparent',
  white: '#FFFFFF',
  grey1: '#F4F6FC',
  grey2: '#D0D7E8',
  grey3: '#ABB5CD',
  grey4: '#7E8BA8',
  grey5: '#535E79',
  grey6: '#404961',
  grey7: '#293040',
  grey8: '#10141D',
  black: '#000000',
  lightBlueGrey: '#EBF0F3',
  brightBlue: '#00CAE0',
  brightBlue2: '#F5FBFF',
  brightBlue3: '#DEEBF4',
  blue: '#0590D5',
  blue2: '#0A2447',
  blue3: '#04A5D5',
  blue4: '#0E5996',
  blue5: '#4150BF',
  blue6: '#00A2E2',
  darkBlue: '#214595',
  darkBlue2: '#193678',
  navy: '#0A2447',
  navy2: '#031227',
  red: '#FF084B',
  red2: '#F20045',
  lightRed: '#FFE4EC',
  darkRed: '#CC0946',
  green: '#55983A',
  darkGreen: '#447C27',
  orange: '#FF7A3F',
  darkOrange: '#FF551A',
  yellow: '#FFB140',
  yellow2: '#FFF3E3',
  yellow3: '#FFD399',
  yellowHighlight: '#FFE852',
  selectedHighlight: '#FFB140',
  darkYellow: '#F7941E',
  violet: '#4b10ba',
  pacificBlue: '#00A0C2',
  purple: '#9141bf',
  easternBlue: '#007893',
  turquoise: '#00A4C4',
} as const;

export const plumbsPalette = {
  primary: '#0590D5',
} as const;

export const standardsPalette = {
  primary: '#214595',
} as const;
