import { getPermissionsByContentType } from 'src/features/auth/helpers';
import { ContentConfig, Contents } from 'src/features/content/config';
import { ContentTypeSimplified } from 'src/features/content/types';

import { type UserPermissions } from '../../auth/types';

interface FilterConfig {
  labelI18nKey: string;
  labelInListI18nKey?: string;
  disableTextTransform?: boolean;
  contentTypeSimplified?: ContentTypeSimplified;
  id: string;
}

export class FavoritesFilter {
  id: string;
  labelI18nKey: string;
  labelInListI18nKey?: string;
  disableTextTransform?: boolean;
  private readonly contentConfig?: ContentConfig;

  constructor(config: FilterConfig) {
    this.id = config.id;
    this.labelI18nKey = config.labelI18nKey;
    this.labelInListI18nKey = config.labelInListI18nKey;
    this.disableTextTransform = config.disableTextTransform;
    if (config.contentTypeSimplified) {
      this.contentConfig = Contents.getContentConfig(config.contentTypeSimplified);
    }
  }

  isAvailableToUser(userPermissions: UserPermissions) {
    if (!this.contentConfig) {
      return true;
    }
    const permission = getPermissionsByContentType(this.contentConfig.contentTypeSimplified).access;
    return userPermissions[permission];
  }

  get contentTypeForBackend() {
    return this.contentConfig?.contentTypes.join();
  }

  get isPro() {
    return !!this.contentConfig?.isPro;
  }
}

class FavoritesFiltersSet {
  private readonly filters: FavoritesFilter[];
  private readonly filtersById: Record<string, FavoritesFilter>;
  defaultFilter: FavoritesFilter;

  constructor(filters: FavoritesFilter[]) {
    this.defaultFilter = filters[0];
    this.filters = filters;
    this.filtersById = filters.reduce<Record<string, FavoritesFilter>>((obj, filter) => {
      return {
        ...obj,
        [filter.id]: filter,
      };
    }, {});
  }

  getAvailableFilters(userPermissions: UserPermissions) {
    return this.filters.filter((item) => item.isAvailableToUser(userPermissions));
  }

  getFilterById(filterId: string) {
    return this.filtersById[filterId];
  }
}

const AllFilter = new FavoritesFilter({
  id: 'all',
  labelI18nKey: 'favorites:all',
});

const DrugsFilter = new FavoritesFilter({
  id: 'filter-monograph',
  contentTypeSimplified: 'drug',
  labelI18nKey: 'favorites:drugMonographs',
});

const DxTxFilter = new FavoritesFilter({
  id: 'filter-dx-tx',
  contentTypeSimplified: 'dx-tx',
  labelI18nKey: 'favorites:clinicalMonographs',
  labelInListI18nKey: 'favorites:clinicalMonographsDropdown',
});

const DDxFilter = new FavoritesFilter({
  id: 'filter-ddx',
  contentTypeSimplified: 'ddx',

  labelI18nKey: 'favorites:ddx',
  labelInListI18nKey: 'favorites:ddxDropdown',
  disableTextTransform: true,
});

const AppendixFilter = new FavoritesFilter({
  id: 'filter-appendix',
  contentTypeSimplified: 'appendix',
  labelI18nKey: 'favorites:appendix',
});

const AlgorithmsFilter = new FavoritesFilter({
  id: 'filter-algorithm',
  contentTypeSimplified: 'algorithm',
  labelI18nKey: 'favorites:algorithms',
});

const DrugHandoutsFilter = new FavoritesFilter({
  id: 'filter-drug-handout',
  contentTypeSimplified: 'drug-handout',
  labelI18nKey: 'favorites:drugHandouts',
});

const ClinicalHandoutsFilter = new FavoritesFilter({
  id: 'filter-clinical-handout',
  contentTypeSimplified: 'clinical-handout',
  labelI18nKey: 'favorites:clinicalHandouts',
});

export const FavoritesFilters = new FavoritesFiltersSet([
  AllFilter,
  DxTxFilter,
  DDxFilter,
  DrugsFilter,
  AlgorithmsFilter,
  ClinicalHandoutsFilter,
  DrugHandoutsFilter,
  AppendixFilter,
]);
