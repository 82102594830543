import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { Container, StyledText, TextButton, TilesMenu } from 'src/components';
import { TilesMenuItem } from 'src/components/TilesMenu/TilesMenu';
import { IPSignOutConfirmModal } from 'src/features/auth/components/IPSignOutConfirmModal';
import { useSignOut, useUserInfo } from 'src/features/auth/hooks';
import {
  AlgorithmConfig,
  AppendixConfig,
  ClinicalHandoutConfig,
  ContentConfig,
  DrugConfig,
  DrugHandoutConfig,
  DxTxConfig,
} from 'src/features/content/config';
import { HomeScreenSharedAccount as HomeScreenSharedAccountOld } from 'src/features/home/screens/HomeScreenSharedAccount.old';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { palette, typography } from 'src/styles';

export const HomeScreenSharedAccountNew: React.FC = () => {
  const { isPro } = useUserInfo();
  const { confirmationModal, handleSignOutPress } = useSignOut({ IPSignOutConfirmModal });
  const { t } = useTranslation('ipLoginUser');

  return (
    <Container style={styles.wrapper} maxWidth="narrow">
      <TilesMenu
        items={
          isPro
            ? [
                [clinicalBriefsItem, drugsItem],
                [dicItem, algorithmsItem],
                [clinicalHandoutsItem, drugHandoutsItem],
              ]
            : [
                [drugsItem, dicItem],
                [drugHandoutsItem, appendixItem],
              ]
        }
      />
      <View style={styles.loginToPersonalSection}>
        <StyledText style={typography.text2}>{t('needToAccessToPersonal')} </StyledText>
        <TextButton
          bold={false}
          style={typography.text2}
          onPress={handleSignOutPress}
          testID="sign-in-here-btn"
        >
          {t('signInHere')}
        </TextButton>
      </View>
      {confirmationModal}
    </Container>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    paddingTop: 24,
  },
  loginToPersonalSection: {
    paddingVertical: 12,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: 24,
    borderRadius: 5,
    backgroundColor: palette.grey1,
  },
});

const drugsItem: TilesMenuItem = {
  key: 'dm',
  title: i18n.t('ipLoginUser:drugMonographs'),
  description: i18n.t('ipLoginUser:drugsDescription'),
  icon: 'drug-monograph',
  testID: 'drug-monograph-tile',
  ...getContentProps(DrugConfig),
};

const dicItem: TilesMenuItem = {
  key: 'dic',
  linkProps: {
    to: {
      route: Route.DIC,
    },
  },
  title: i18n.t('ipLoginUser:dic'),
  description: i18n.t('ipLoginUser:dicDescription'),
  icon: 'dic',
  testID: 'dic-tile',
};

const clinicalHandoutsItem: TilesMenuItem = {
  key: 'clinical-h',
  title: i18n.t('ipLoginUser:clinicalHandouts'),
  description: i18n.t('ipLoginUser:clinicalHandoutsDescription'),
  icon: 'stethoscope-text',
  testID: 'clinical-handouts-tile',
  ...getContentProps(ClinicalHandoutConfig),
};

const drugHandoutsItem: TilesMenuItem = {
  key: 'drug-h',
  title: i18n.t('ipLoginUser:drugHandouts'),
  description: i18n.t('ipLoginUser:drugHandoutsDescription'),
  icon: 'medication-guide',
  testID: 'drug-handouts-tile',
  ...getContentProps(DrugHandoutConfig),
};

const appendixItem: TilesMenuItem = {
  key: 'appendix',
  title: i18n.t('ipLoginUser:appendix'),
  description: i18n.t('ipLoginUser:appendixDescription'),
  icon: 'document-attachment',
  testID: 'appendix-tile',
  ...getContentProps(AppendixConfig),
};

const clinicalBriefsItem: TilesMenuItem = {
  key: 'cb',
  title: i18n.t('ipLoginUser:clinicalMonographs'),
  description: i18n.t('ipLoginUser:clinicalBriefsDescription'),
  icon: 'virus',
  testID: 'clinical-brief-tile',
  ...getContentProps(DxTxConfig),
};

const algorithmsItem: TilesMenuItem = {
  key: 'algorithms',
  title: i18n.t('screens:algorithms'),
  description: i18n.t('ipLoginUser:algorithmsDescription'),
  icon: 'algorithm',
  testID: 'algorithm-tile',
  ...getContentProps(AlgorithmConfig),
};

function getContentProps(contentConfig: ContentConfig): Pick<TilesMenuItem, 'linkProps' | 'showProBadge'> {
  return {
    linkProps: {
      to: { route: contentConfig.routes.list },
    },
    showProBadge: contentConfig.isPro,
  };
}

export const HomeScreenSharedAccount = () => {
  const {
    permissions: { hasAccessToStandards },
  } = useUserInfo();
  const Component = hasAccessToStandards ? HomeScreenSharedAccountNew : HomeScreenSharedAccountOld;

  return <Component />;
};
