import { ItemListByLetterFetchParams } from 'src/constants/types';

export enum ContentType {
  VeterinaryMedicationGuide = 'veterinary-medication-guide',
  Drug = 'monograph',
  Appendix = 'appendix',
  Algorithm = 'algorithm',
  DxTxSignSituation = 'clinical-brief-sign-situation',
  DxTxMisc = 'clinical-brief-misc',
  DxTxCondition = 'clinical-brief-condition',
  HandoutProcedure = 'handout-procedure',
  HandoutClinicalConditions = 'handout-clinical-conditions',
  HandoutVaccine = 'handout-vaccine',
  HandoutMisc = 'handout-misc',
  DrugHandout = 'drug-handout',
  DrugAdministrationHandouts = 'drug-administration-handouts',
  DDx = 'differential-diagnosis',
}

export enum ContentListType {
  Algorithms = 'algorithms',
  Appendix = 'appendix',
  DxTx = 'dx-tx',
  ClinicalHandouts = 'clinical-handouts',
  DrugHandouts = 'drug-handouts',
  Drugs = 'drugs',
  DDx = 'differential-diagnosis',
}

export enum ContentStatus {
  New = 'New',
  None = 'None',
  Update = 'Updated',
  Archived = 'Archived',
  InDevelopment = 'In Development',
  Beta = 'Beta',
}

export const PUBLICATION_DETAILS_SECTION_ID = 'publication_details';
export const SECTIONS_WITHOUT_NOTES = [PUBLICATION_DETAILS_SECTION_ID];

export const UPDATED_LIST_PARAM = Object.freeze({ status: ContentStatus.Update });
export const NEW_LIST_PARAM = Object.freeze({ status: ContentStatus.New });

export const contentQueryKeys = {
  allLists: ['content-list'] as const,
  list: (type: ContentListType, params?: Partial<ItemListByLetterFetchParams>) =>
    [...contentQueryKeys.allLists, type, params] as const,
};

export const OVERVIEW_TOC_ITEM_ID = 'default-section';
