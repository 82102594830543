import { StyleSheet, View } from 'react-native';

import { GradientBar } from 'src/components';
import { ContentListItemCardSimplified as ContentListItemCardSimplifiedOld } from 'src/components/ContentListItem/ContentListItemCardSimplified.old';
import { useUserInfo } from 'src/features/auth/hooks';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { ContentStatus, ContentType } from 'src/features/content/constants';
import { isIOS, isAndroid } from 'src/helpers/common';
import { ifWeb, palette } from 'src/styles';

import { ContentTypeBadge } from '../ContentTypeBadge';
import { Icon } from '../Icon/Icon';
import { ListItem } from '../ListItem';

interface Props
  extends Pick<
    React.ComponentProps<typeof ListItem>,
    | 'title'
    | 'linkProps'
    | 'onPress'
    | 'bottomBorder'
    | 'topBorder'
    | 'sideBorder'
    | 'roundedBottomBorder'
    | 'roundedTopBorder'
  > {
  contentType: ContentType;
  inDevelopment?: boolean;
  sideBar?: boolean;
  testID?: string;
  isNew?: boolean;
  favoriteItem?: boolean;
}

export const ContentListItemCardSimplifiedNew: React.FC<Props> = ({
  contentType,
  inDevelopment,
  sideBar,
  ...listItemProps
}) => {
  const { primary } = useTheme();
  const { isPro } = useUserInfo();

  return (
    <GradientBar
      gradientType="standardsSideBarSelectedGradient"
      hideGradient={!listItemProps.favoriteItem || isIOS || isAndroid}
    >
      <ListItem
        {...listItemProps}
        style={!isPro && styles.listItem}
        rightColumn={
          <View style={styles.rightColumn}>
            <View style={styles.badge}>
              <ContentTypeBadge
                contentType={contentType}
                statusType={inDevelopment ? ContentStatus.InDevelopment : undefined}
              />
            </View>
            <Icon
              name="chevron-right"
              color={inDevelopment ? palette.grey4 : isPro ? palette.grey5 : palette.navy}
              width={10}
            />
          </View>
        }
        textHoveredStyle={{
          ...ifWeb({
            color: primary,
          }),
        }}
        textStyle={inDevelopment && styles.inDevelopmentText}
        sideBar={sideBar}
      />
    </GradientBar>
  );
};

const styles = StyleSheet.create({
  listItem: {
    backgroundColor: palette.white,
  },
  rightColumn: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  badge: {
    marginRight: 13,
    marginLeft: 8,
  },
  inDevelopmentText: {
    color: palette.grey5,
  },
});

export const ContentListItemCardSimplified: React.FC<Props> = (props) => {
  const {
    permissions: { hasAccessToStandards },
  } = useUserInfo();
  const Component = hasAccessToStandards
    ? ContentListItemCardSimplifiedNew
    : ContentListItemCardSimplifiedOld;

  return <Component {...props} />;
};
