import { ReactNode } from 'react';
import { StyleSheet, View } from 'react-native';

import { Icon, StyledText, GradientBar } from 'src/components';
import { useUserInfo } from 'src/features/auth/hooks';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { SideBarLink as SideBarLinkOld } from 'src/navigation/components/SideBar/SideBarLink.old';
import { useSideBarContext } from 'src/navigation/contexts/SideBarContext';
import { useRoute } from 'src/navigation/hooks/useRoute';
import { Route } from 'src/navigation/routes';
import { palette, typography, ifWeb } from 'src/styles';

import { Link } from '../Link';

interface Props<R extends Route = any> {
  isFirst?: boolean;
  disabled?: boolean;
  pro?: boolean;
  text: string;
  to: { route: R };
  testID?: string;
  children?: (selected: boolean) => ReactNode;
  onPress?: () => void;
}

export const SideBarLinkNew: React.FC<Props> = ({ children, pro, text, isFirst, onPress, ...item }) => {
  const { primary } = useTheme();
  const { name: currentRoute } = useRoute();
  const { setSideBarOpen, isSidebarPermanentlyVisible } = useSideBarContext();
  const { isPro } = useUserInfo();

  const isSelected = currentRoute === item.to.route;

  const gradientType = isSelected
    ? isPro
      ? 'proSelectedGradient'
      : 'proGradient'
    : isPro
    ? 'standardsSideBarSelectedGradient'
    : 'whiteGradient';

  const handleLinkPress = () => {
    onPress?.();
    if (!isSidebarPermanentlyVisible) {
      setSideBarOpen(false);
    }
  };

  return (
    <Link {...item} onPress={handleLinkPress}>
      {(hovered) => (
        <View style={hovered && isPro ? styles.hoveredBackground : styles.transparentBackground}>
          <GradientBar
            gradientType={gradientType}
            style={[
              styles.item,
              !isFirst && (isPro ? styles.itemSeparatorDark : styles.itemSeparatorLight),
            ]}
          >
            <View style={styles.itemInnerWrapper}>
              <View style={styles.itemTextWrapper}>
                <StyledText
                  style={[
                    styles.itemText,
                    isPro && styles.itemTextWhite,
                    !isPro && hovered && { color: primary },
                    isSelected && styles.itemTextSelected,
                  ]}
                >
                  {text}
                </StyledText>
                {pro && !isPro && (
                  <View style={styles.standardsBadgeWrapper}>
                    <GradientBar gradientType={isSelected ? 'whiteGradient' : 'proStandardsBadgeGradient'}>
                      <StyledText style={[styles.standardsBadge, isSelected && { color: primary }]}>
                        Standards
                      </StyledText>
                    </GradientBar>
                  </View>
                )}
                {children?.(isSelected)}
              </View>
              <Icon
                name="chevron-right"
                width={10}
                color={isSelected ? palette.white : isPro ? palette.grey5 : palette.navy}
              />
            </View>
          </GradientBar>
        </View>
      )}
    </Link>
  );
};
const styles = StyleSheet.create({
  transparentBackground: {
    backgroundColor: palette.transparent,
    transition: 'all 250ms',
  },
  hoveredBackground: {
    backgroundColor: palette.hoverOverlay,
    transition: 'all 250ms',
  },
  itemSeparatorLight: {
    borderTopWidth: 1,
    borderColor: palette.grey2,
  },
  itemSeparatorDark: {
    borderTopWidth: 1,
    borderColor: palette.grey7,
  },
  item: {
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
  itemInnerWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  itemTextWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemText: {
    ...typography.body3Bold,
    color: palette.navy,
    paddingRight: 8,
    ...ifWeb({
      transitionProperty: 'color',
    }),
  },
  itemTextWhite: {
    color: palette.white,
  },
  itemTextSelected: {
    color: palette.white,
  },
  standardsBadgeWrapper: {
    overflow: 'hidden',
    borderRadius: 5,
  },
  standardsBadge: {
    fontSize: 12,
    fontWeight: 700,
    textTransform: 'uppercase',
    color: palette.white,
    borderRadius: 5,
    height: 20,
    paddingLeft: 6,
    paddingRight: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export const SideBarLink: React.FC<Props> = (props) => {
  const {
    permissions: { hasAccessToStandards },
  } = useUserInfo();
  const Component = hasAccessToStandards ? SideBarLinkNew : SideBarLinkOld;

  return <Component {...props} />;
};
