import dayjs from 'dayjs';
import omit from 'lodash/omit';
import * as R from 'ramda';
import React from 'react';
import { StyleSheet } from 'react-native';

import { BaseButton, InputItem, Select, StyledText } from 'src/components';
import { getMessagesFromErrorResponse } from 'src/errorHandling/utils';
import { useUserInfo } from 'src/features/auth/hooks';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { AccountInformationSection as Section } from 'src/features/profile/components/AccountInformationSection';
import { isWeb, showNotification } from 'src/helpers';
import { Yup } from 'src/helpers/validation';
import { useDeviceInfo } from 'src/hooks';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { Link } from 'src/navigation/components';

import { DateInput, dateInputValidators } from './DateInput';
import { StudentUniversitySelect } from './StudentUniversitySelect';
import { StudentSubscriptionEligibilityStatus } from '../constants';
import {
  useStudentSubscriptionEligibility,
  useUpdateStudentSubscriptionEligibility,
} from '../hooks/queries';
import { useStudentForm } from '../hooks/useStudentForm';
import type {
  StudentSubscriptionEligibilityBase,
  StudentSubscriptionEligibilityErrors,
  StudentSubscriptionEligibilityParams,
} from '../types';

const validationSchema: Yup.SchemaOf<StudentSubscriptionEligibilityParams> = Yup.object().shape({
  country: Yup.string().required(),
  studentEmail: Yup.string().email(i18n.t('validation:enterValidEmail')).required(),
  organizationId: Yup.number().required(),
  organizationName: Yup.string().required(),
  ...dateInputValidators,
});

export const StudentSubscriptionEligibility: React.FC = () => {
  const { primary } = useTheme();
  const { data, isLoading } = useStudentSubscriptionEligibility();
  const { mutate: updateStudentSubscriptionEligibility, isPending } =
    useUpdateStudentSubscriptionEligibility();
  const { planCode } = useUserInfo();
  const { isTablet } = useDeviceInfo();

  const birthDateSplitted = data?.birthDate.split('-') || null;

  const studentSubscriptionEligibilityInitialValues: StudentSubscriptionEligibilityParams = {
    country: data?.country || '',
    studentEmail: data?.studentEmail || '',
    organizationId: data?.organizationId || 1,
    organizationName: data?.organizationName || '',
    birthDate: birthDateSplitted ? parseInt(birthDateSplitted[2], 10) : null,
    birthMonth: birthDateSplitted ? parseInt(birthDateSplitted[1], 10) - 1 : null,
    birthYear: birthDateSplitted ? parseInt(birthDateSplitted[0], 10) : null,
  };

  const onSubmit = (values: StudentSubscriptionEligibilityParams) => {
    const birthDate = dayjs({
      dates: values.birthDate || undefined,
      months: values.birthMonth || undefined,
      years: values.birthYear || undefined,
    }).format('YYYY-MM-DD');
    const payload = { ...omit(values, 'birthYear', 'birthMonth'), birthDate };

    updateStudentSubscriptionEligibilityData(payload);
  };

  const updateStudentSubscriptionEligibilityData = async (values: StudentSubscriptionEligibilityBase) => {
    updateStudentSubscriptionEligibility(values, {
      onError: (err: any) => {
        const { detail, ...rest } = getMessagesFromErrorResponse<StudentSubscriptionEligibilityErrors>(err);
        if (!R.isEmpty(rest)) {
          setErrors(rest);
        } else {
          showNotification({ type: 'error', title: detail });
        }
      },
    });
  };

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    setErrors,
    countriesOptions,
    handleOrganizationChange,
    handleCountryChange,
  } = useStudentForm<StudentSubscriptionEligibilityParams>(
    studentSubscriptionEligibilityInitialValues,
    planCode!,
    validationSchema,
    onSubmit,
    true,
  );

  const isStudentReverificationInfoNeeded =
    data?.status === StudentSubscriptionEligibilityStatus.ReverificationInfoNeeded;

  const content = (
    <>
      <Section.Form>
        <Section.Row>
          <Section.Item>
            <InputItem
              inputMode="email"
              label={i18n.t('subscriptionProcess:emailLabel')}
              value={values.studentEmail || ''}
              onChangeText={handleChange('studentEmail')!}
              description={i18n.t('subscriptionProcess:studentEmailDescription')}
              error={errors.studentEmail}
              touched={touched.studentEmail}
              readOnly={isStudentReverificationInfoNeeded}
              testID="student-email-input"
            />
          </Section.Item>
          <Section.Item>
            <DateInput
              values={values}
              touched={touched}
              readOnly={isStudentReverificationInfoNeeded}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              errors={errors}
            />
          </Section.Item>
        </Section.Row>
        <Section.Row>
          <Section.Item>
            <Select
              options={countriesOptions}
              value={values.country}
              onChange={handleCountryChange}
              label={i18n.t('user:countryLabel')}
              error={errors.country}
              touched={touched.country}
              readOnly={isStudentReverificationInfoNeeded}
              searchType="internal"
              testID="country-select"
            />
          </Section.Item>
          <Section.Item>
            {!!values.country && (
              <StudentUniversitySelect
                organizationId={values.organizationId}
                organizationName={values.organizationName}
                onChange={handleOrganizationChange}
                error={errors.organizationName}
                touched={touched.organizationName}
                readOnly={isStudentReverificationInfoNeeded}
                country={values.country}
                plan={planCode!}
              />
            )}
          </Section.Item>
        </Section.Row>
      </Section.Form>
      <Section.ButtonsWrapper style={!isTablet && styles.buttonsWrapperMobile}>
        {isWeb && isStudentReverificationInfoNeeded && (
          <Link
            to={{ route: Route.StudentReverification }}
            wrapperStyle={[styles.linkWrapper, !isTablet && styles.linkWrapperMobile]}
          >
            <StyledText style={{ color: primary }}>
              {i18n.t('accountInformation:studentSubscriptionEligibilityLink')}
            </StyledText>
          </Link>
        )}
        <BaseButton
          title={i18n.t('saveChanges')}
          loading={isPending}
          disabled={isStudentReverificationInfoNeeded}
          onPress={handleSubmit}
          testID="submit-btn"
          variant="gradient"
        />
      </Section.ButtonsWrapper>
    </>
  );

  return isWeb ? (
    <Section
      title={i18n.t('accountInformation:studentSubscriptionEligibility')}
      isLoading={isLoading}
      testID="section-student-subscription-eligibility"
    >
      {content}
    </Section>
  ) : (
    content
  );
};

const styles = StyleSheet.create({
  buttonsWrapperMobile: {
    flexDirection: 'column-reverse',
  },
  linkWrapper: {
    alignSelf: 'flex-end',
  },
  linkWrapperMobile: {
    alignSelf: 'flex-start',

    marginTop: 30,
  },
});
