import React from 'react';
import { View, StyleSheet, ScrollView, StyleProp, ViewStyle } from 'react-native';

import { MODAL_WRAPPER_ID } from 'src/constants/constants';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { useBlockBackButton } from 'src/hooks/useBlockBackButton';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { ifWeb, palette, typography, zindex } from 'src/styles';

import { IconButton } from './IconButton';
import { StyledText } from './StyledText';
import { SystemBars } from './SystemBars';

type Props = {
  scroll?: boolean;
  fitContents?: boolean;
  padded?: boolean;
  close?: () => void;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  testID?: string;
  style?: StyleProp<ViewStyle>;
  scrollContainerStyle?: StyleProp<ViewStyle>;
};

export const BaseModal: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  scroll = true,
  fitContents,
  padded = true,
  close,
  header,
  footer,
  testID,
  style,
  scrollContainerStyle,
}) => {
  const { isSmallestMobile, isDesktop } = useDeviceInfo();
  const { primary } = useTheme();

  useBlockBackButton();

  return (
    <View
      style={[styles.wrapper, isSmallestMobile && styles.wrapperMobile]}
      testID={testID}
      id={MODAL_WRAPPER_ID}
    >
      <SystemBars style="light" />
      <View style={styles.center}>
        <View
          style={[
            styles.container,
            isDesktop && styles.containerDesktop,
            fitContents && styles.containerFit,
            padded && styles.padded,
            style,
          ]}
        >
          {!!header && (
            <View style={styles.headerWrapper}>
              {typeof header === 'string' ? (
                <StyledText style={[styles.headerText, typography.body5Bold]} testID="modal-header">
                  {header}
                </StyledText>
              ) : (
                header
              )}
            </View>
          )}
          {close && (
            <IconButton
              name="close"
              onPress={close}
              containerStyle={styles.closeIcon}
              color={primary}
              onHoverColor={palette.navy}
              width={15}
              testID="modal-close-btn"
              strokeWidth={1.6}
            />
          )}
          <ScrollView
            style={[styles.webHeight, scrollContainerStyle]}
            contentContainerStyle={styles.webHeight}
            scrollEnabled={scroll}
          >
            {children}
          </ScrollView>
          <View style={styles.footerWrapper}>{footer}</View>
        </View>
      </View>
    </View>
  );
};

export const styles = StyleSheet.create({
  wrapper: {
    top: 0,
    left: 0,
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    padding: 32,
    backgroundColor: palette.blueScrim,
    ...ifWeb(
      {
        position: 'fixed',
        zIndex: zindex.modal,
      },
      {
        position: 'absolute',
      },
    ),
  },
  wrapperMobile: {
    padding: 16,
  },
  center: {
    maxHeight: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  container: {
    maxHeight: '100%',
    width: '100%',
    backgroundColor: palette.white,
    borderRadius: 5,
  },
  containerFit: {
    width: 'auto',
    maxWidth: '100%',
  },
  containerDesktop: {
    width: 840,
  },
  padded: {
    ...ifWeb(
      {
        paddingHorizontal: 24,
        paddingTop: 24,
        paddingBottom: 20,
      },
      {
        padding: 30,
      },
    ),
  },
  headerWrapper: {
    width: '100%',
    minHeight: 15,
  },
  footerWrapper: {
    width: '100%',
  },
  closeIcon: {
    position: 'absolute',
    zIndex: 1,
    right: 16,
    top: 16,
  },
  webHeight: {
    ...ifWeb({
      height: '100%',
    }),
  },
  headerText: {
    color: palette.navy,
    marginBottom: 16,
  },
});
