import { debounce } from 'lodash';
import { forwardRef, useState, useMemo, useEffect, useCallback, useRef } from 'react';
import {
  TextInput,
  StyleSheet,
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
  TextInputProps,
  LayoutChangeEvent,
} from 'react-native';

import { DEFAULT_MAX_FONT_SIZE_MULTIPLIER } from 'src/constants/constants';
import { i18n } from 'src/locale';
import { fonts, palette } from 'src/styles';

import { useFindOnPage } from '../hooks/useFindOnPage';

type FindOnPageInputProps = TextInputProps & {
  totalFound: number;
  contentTitle: string;
  onPrevHighlight?: () => void;
  onNextHighlight?: () => void;
};

export const FindOnPageInput = forwardRef<TextInput, FindOnPageInputProps>((props, ref) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [inputWidth, setInputWidth] = useState<number>(0);
  const [truncatedPlaceholder, setTruncatedPlaceholder] = useState<string>('');

  const findOnPage = useFindOnPage();
  // Create a debounced version of the function that handles search text
  const debounceRef = useRef(
    debounce((text: string) => {
      findOnPage?.onSetSearchText?.(text);
    }, 300),
  );

  const onKeyPress = (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    const inputRef = ref && typeof ref !== 'function' ? ref.current : null;
    if (inputRef?.isFocused() && props.totalFound > 0) {
      const { key } = event.nativeEvent;
      if (key === 'ArrowUp') {
        props.onPrevHighlight?.();
      } else if (key === 'ArrowDown' || key === 'Enter') {
        props.onNextHighlight?.();
      }
    }
  };

  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlur = () => {
    setIsFocused(false);
  };

  // Use useCallback to memoize the function and avoid unnecessary re-renders
  const onChangeInput = useCallback((text: string) => {
    debounceRef.current(text);
  }, []);

  const handleLayout = (event: LayoutChangeEvent) => {
    const { width } = event.nativeEvent.layout;
    setInputWidth(width);
  };

  // Function to truncate the placeholder
  const truncateText = useMemo(() => {
    return (text: string, maxWidth: number) => {
      const maxChars = Math.floor(maxWidth / 7);
      return text.length > maxChars ? `${text.substring(0, maxChars - 3)}...` : text;
    };
  }, []);

  useEffect(() => {
    const placeholderText = i18n.t('findOnPage:input', { contentTitle: props.contentTitle });
    setTruncatedPlaceholder(truncateText(placeholderText, inputWidth));
  }, [inputWidth, props.contentTitle, truncateText]);

  useEffect(() => {
    const debounceFunction = debounceRef.current;
    return () => {
      debounceFunction.cancel();
    };
  }, []);

  return (
    <TextInput
      ref={ref}
      style={styles.input}
      placeholder={truncatedPlaceholder}
      placeholderTextColor={isFocused ? palette.grey5 : palette.grey6}
      autoCapitalize="none"
      blurOnSubmit={false}
      onFocus={onFocus}
      onBlur={onBlur}
      maxFontSizeMultiplier={DEFAULT_MAX_FONT_SIZE_MULTIPLIER}
      testID="fop-search-input"
      onKeyPress={onKeyPress}
      onChangeText={onChangeInput}
      onLayout={handleLayout} // Capture the layout changes
      {...props}
    />
  );
});

const styles = StyleSheet.create({
  input: {
    color: palette.grey8,
    flex: 1,
    padding: 10,
    paddingLeft: 0,
    fontFamily: fonts.sourceSans,
    fontSize: 16,
  },
});
