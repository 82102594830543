import { Trans } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { InfoModal, StyledText } from 'src/components';
import { CONTACT_SALES_LINK, SUPPORT_LINK } from 'src/constants/externalLinks';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { Link } from 'src/navigation/components';
import { typography } from 'src/styles';

interface Props {
  close(): void;
}

export const ExpiredSharedAccountModal: React.FC<Props> = ({ close }) => {
  const { primary } = useTheme();
  const { isSmallMobile } = useDeviceInfo();

  const handleSignInButtonPress = () => {
    close();
  };

  return (
    <InfoModal
      icon={isSmallMobile ? undefined : 'moon'}
      header={i18n.t('signIn:expiredAccount:yourAccountIsNotActive')}
      testID="modal-account-inactive"
    >
      <InfoModal.Content>
        <Trans
          i18nKey="signIn:expiredAccount:restoreInfo"
          components={{
            p: <StyledText />,
            sales: (
              <Link
                style={[{ color: primary }, styles.link]}
                external
                to={CONTACT_SALES_LINK}
                wrapper="text"
              />
            ),
            support: (
              <Link style={[{ color: primary }, styles.link]} external to={SUPPORT_LINK} wrapper="text" />
            ),
          }}
        />
      </InfoModal.Content>
      <InfoModal.Content small>
        <Trans
          i18nKey="signIn:expiredAccount:needOwnAccountInfo"
          components={{
            p: <StyledText />,
            b: <StyledText style={styles.b} />,
          }}
        />
      </InfoModal.Content>
      <InfoModal.Buttons>
        <InfoModal.Button
          title={i18n.t('signIn:expiredAccount:createAnAccount')}
          linkProps={{
            to: {
              route: Route.SignUp,
            },
            onPress: close,
          }}
        />
        <InfoModal.Button
          title={i18n.t('signIn:expiredAccount:signInWithAnotherAccount')}
          variant="secondary"
          onPress={handleSignInButtonPress}
        />
      </InfoModal.Buttons>
    </InfoModal>
  );
};

const styles = StyleSheet.create({
  link: {
    textDecorationLine: 'underline',
  },
  b: {
    ...typography.weightBold,
  },
});
