import React from 'react';
import { StyleSheet, View } from 'react-native';

import {
  CircleIconButton,
  GradientBar,
  Icon,
  IconButton,
  Pressable,
  ProBadge,
  StyledText,
} from 'src/components';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { AppcuesService } from 'src/features/tracking';
import { isWeb } from 'src/helpers';
import { Link } from 'src/navigation/components';
import { getShadow, getShadowNative, ifWeb, palette, typography } from 'src/styles';

import { usePromoboxUpdate } from '../hooks';
import { Promobox } from '../types';

const CLOSE_ICON_SIZE = 28;
const CLOSE_ICON_OFFSET = Math.round(CLOSE_ICON_SIZE / 3);
const HIT_SLOP = { top: 15, bottom: 15, left: 15, right: 15 };

interface Props {
  config: Promobox;
  onRemove(): void;
}

interface CTAProps extends Pick<Promobox, 'appcuesFlowId' | 'ctaLink'>, React.PropsWithChildren {
  onPress(): void;
}

export const PromoBoxCarouselItem: React.FC<Props> = ({
  config: { slug, copyText, headlineText, ctaLink, ctaText, tag, appcuesFlowId },
  onRemove,
}) => {
  const { primary } = useTheme();
  const isProBoxVariant = tag === 'Pro';

  const { mutate: update } = usePromoboxUpdate();

  const onLinkPress = () => {
    update({ slug, action: 'click' });
    onRemove();
  };

  const onClose = () => {
    update({ slug, action: 'close' });
    onRemove();
  };

  const textStyle = [isProBoxVariant && styles.whiteText];

  return (
    <View style={styles.wrapper}>
      <View style={styles.shadow}>
        <GradientBar style={[styles.content]} gradientType="proGradient" hideGradient={!isProBoxVariant}>
          {!isWeb && isProBoxVariant && <ProBadge variant="outlined" width={50} style={styles.proBadge} />}
          <View style={styles.flexWrapper}>
            <View style={styles.headerWrapper}>
              <View style={styles.flexWrapper}>
                {isProBoxVariant && isWeb && <ProBadge variant="inverted" style={styles.proBadge} />}
                <StyledText style={[styles.headerText, textStyle]}>{headlineText}</StyledText>
              </View>
              {isWeb && (
                <IconButton
                  name="close"
                  color={isProBoxVariant ? palette.white : primary}
                  onHoverColor={isProBoxVariant ? palette.white : primary}
                  onPress={onClose}
                  width={11}
                  containerStyle={styles.closeButton}
                />
              )}
            </View>
            {copyText && <StyledText style={[styles.copyText, textStyle]}>{copyText}</StyledText>}
            <CTALink appcuesFlowId={appcuesFlowId} ctaLink={ctaLink} onPress={onLinkPress}>
              <View style={styles.calloutRow}>
                <StyledText
                  style={[
                    { color: primary },
                    styles.calloutText,
                    isProBoxVariant && styles.calloutTextPro,
                    textStyle,
                  ]}
                >
                  {ctaText}
                </StyledText>
                <Icon
                  name="arrow-right-thin"
                  expander={8}
                  width={19}
                  color={isProBoxVariant ? palette.white : primary}
                />
              </View>
            </CTALink>
          </View>
        </GradientBar>
      </View>
      {!isWeb && (
        <CircleIconButton
          name="close"
          containerStyle={styles.closeButtonCircle}
          containerSize={CLOSE_ICON_SIZE}
          width={10}
          onPress={onClose}
          hitSlop={HIT_SLOP}
        />
      )}
    </View>
  );
};

const CTALink: React.FC<CTAProps> = ({ ctaLink, appcuesFlowId, onPress, children }) => {
  const handleApcuesFlowPress = () => {
    AppcuesService.displayFlow(appcuesFlowId!);
    onPress();
  };

  if (ctaLink) {
    return (
      <Link to={ctaLink} external onPress={onPress}>
        <View style={styles.linkWrapper}>{children}</View>
      </Link>
    );
  }

  if (appcuesFlowId) {
    return (
      <Pressable onPress={handleApcuesFlowPress}>
        <View style={styles.linkWrapper}>{children}</View>
      </Pressable>
    );
  }

  return null;
};

const styles = StyleSheet.create({
  wrapper: {
    ...ifWeb(
      {
        minHeight: 194,
      },
      {
        maxWidth: 350,
        height: 130,
      },
    ),
  },
  // additional shadow element to make sure the shadow is visible on iOS
  shadow: {
    backgroundColor: palette.white, // needed to prevent WARN in RN (to calculating shadow)
    ...getShadow(4, 0.4),
    ...getShadowNative(),
    width: '100%',
    borderRadius: 5,
  },
  content: {
    width: '100%',
    backgroundColor: palette.white,
    borderRadius: 5,
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    overflow: 'hidden',
    ...ifWeb(
      {
        padding: 32,
      },
      {
        padding: 24,
      },
    ),
  },
  flexWrapper: {
    flex: 1,
  },
  headerWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    ...ifWeb({
      marginBottom: 12,
    }),
  },
  headerText: {
    color: palette.navy,
    ...ifWeb(
      {
        ...typography.heading3,
      },
      {
        ...typography.body2Bold,
      },
    ),
  },
  proBadge: {
    ...ifWeb(
      {
        marginBottom: 12,
      },
      {
        marginRight: 22,
      },
    ),
  },
  copyText: {
    color: palette.navy,
    ...typography.body2,
    ...ifWeb({
      marginBottom: 12,
    }),
  },
  calloutRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 2,
  },
  calloutText: {
    ...typography.body2SemiBold,
  },
  calloutTextPro: {
    ...ifWeb(
      {},
      {
        ...typography.weightRegular,
      },
    ),
  },
  closeButton: {
    alignSelf: 'flex-start',
  },
  closeButtonCircle: {
    position: 'absolute',
    right: -CLOSE_ICON_OFFSET,
    top: -CLOSE_ICON_OFFSET,
  },
  whiteText: {
    color: palette.white,
  },
  linkWrapper: {
    alignSelf: 'flex-start',
  },
});
