import React from 'react';
import { StyleProp, StyleSheet, useWindowDimensions, View, ViewStyle } from 'react-native';

import { GradientButton, StyledText } from 'src/components';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { Link } from 'src/navigation/components';
import {
  ExtendedDropdownProps,
  NavigationExtendedDropdown,
  NavigationExtendedDropdownColumn,
  NavigationExtendedDropdownItem,
} from 'src/navigation/types';
import { getShadow, ifWeb, palette, typography } from 'src/styles';

import { ExtendedDropdownButton } from './ExtendedDropdownButton';
import { ExtendedDropdownItem } from './ExtendedDropdownItem';

interface Props extends NavigationExtendedDropdown, ExtendedDropdownProps {
  wrapperStyle?: StyleProp<ViewStyle>;
}

export const ExtendedDropdown: React.FC<Props> = ({ content, button, wrapperStyle, inMobileDropdown }) => {
  const { primary } = useTheme();
  const columns = isSingleColumn(content) ? [content] : content;
  const { width: windowWidth } = useWindowDimensions();
  const wrapperDesktopStyles = [styles.wrapper, styles.wrapperDesktop, { maxWidth: windowWidth - 60 }];

  const getColumnButton = (title: string) => (
    <GradientButton
      title={title}
      fullWidth
      icon="chevron-right-circle"
      style={styles.button}
      iconStyle={(isHovered) => [styles.buttonIcon, isHovered && styles.buttonIconHover]}
    />
  );

  return (
    <View
      style={[styles.wrapper, inMobileDropdown ? styles.wrapperMobile : wrapperDesktopStyles, wrapperStyle]}
    >
      <View style={[styles.columns, inMobileDropdown && styles.columnsMobile]}>
        {columns.map((column, index) => {
          const itemsColumns = isSingleColumnOfItems(column.items) ? [column.items] : column.items;

          return (
            <View
              key={index}
              style={[
                styles.column,
                inMobileDropdown && styles.columnMobile,
                (index === columns.length - 1 || inMobileDropdown) && styles.columnNoBorder,
              ]}
            >
              <View style={styles.textWrapper}>
                {column.title && (
                  <StyledText style={[{ color: primary }, styles.title]}>{column.title}</StyledText>
                )}
                {column.description && (
                  <StyledText style={styles.description}>{column.description}</StyledText>
                )}
              </View>
              <View
                style={[
                  styles.itemsColumns,
                  !(column.title || column.description) && styles.itemsColumnsNoMargin,
                ]}
              >
                {itemsColumns.map((items, index) => (
                  <View key={index} style={styles.itemsColumn}>
                    {items.map((item) => (
                      <ExtendedDropdownItem key={item.title} {...item} />
                    ))}
                  </View>
                ))}
              </View>
              {column.button &&
                (column.button.to ? (
                  <Link to={column.button.to} external={column.button.external}>
                    {getColumnButton(column.button.title)}
                  </Link>
                ) : (
                  getColumnButton(column.button.title)
                ))}
            </View>
          );
        })}
      </View>
      {button && <ExtendedDropdownButton {...button} />}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: palette.white,
  },
  wrapperDesktop: {
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    ...getShadow(4, 0.5),
  },
  wrapperMobile: {
    paddingBottom: 40,
  },
  columns: {
    flexDirection: 'row',
  },
  columnsMobile: {
    flexDirection: 'column',
  },
  column: {
    paddingTop: 60,
    paddingHorizontal: 40,
    paddingBottom: 40,
    borderColor: palette.grey2,
    borderRightWidth: 1,
    width: '100%',
    flex: 1,
  },
  columnNoBorder: {
    borderRightWidth: 0,
  },
  columnMobile: {
    paddingTop: 40,
    paddingHorizontal: 20,
    paddingBottom: 0,
  },
  textWrapper: {
    marginLeft: 20,
  },
  title: {
    marginBottom: 10,
    ...typography.body5,
    ...typography.weightExtraLight,
  },
  description: {
    color: palette.grey6,
    ...typography.body1,
    ...typography.weightLight,
  },
  itemsColumns: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'flex-start',
    marginTop: 30,
  },
  itemsColumnsNoMargin: {
    marginTop: 0,
  },
  itemsColumn: {
    flex: 1,
  },
  button: {
    marginTop: 20,
  },
  buttonIcon: {
    ...ifWeb({
      transitionProperty: 'transform',
    }),
  },
  buttonIconHover: {
    transform: [
      {
        translateX: 5,
      },
    ],
  },
});

function isSingleColumn(
  content: NavigationExtendedDropdownColumn | NavigationExtendedDropdownColumn[],
): content is NavigationExtendedDropdownColumn {
  return !Array.isArray(content);
}

function isSingleColumnOfItems(
  items: NavigationExtendedDropdownItem[] | NavigationExtendedDropdownItem[][],
): items is NavigationExtendedDropdownItem[] {
  return !items[0] || !Array.isArray(items[0]);
}
