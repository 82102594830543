import React, { useCallback } from 'react';
import { View, StyleSheet, ViewStyle } from 'react-native';

import { Icon } from 'src/components/Icon/Icon';
import { Pressable } from 'src/components/Pressable';
import { IconName } from 'src/constants/types';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { ifWeb, palette } from 'src/styles';

interface FindOnPageInputButtonProps {
  iconName: IconName;
  disabled?: boolean;
  testID: string;
  onPress: () => void;
}

const FindOnPageInputButton: React.FC<FindOnPageInputButtonProps> = ({
  iconName,
  disabled = false,
  testID,
  onPress,
}) => {
  const { primary } = useTheme();
  const isCloseButton = iconName === 'close';
  const isDisabledCloseButton = isCloseButton && disabled;

  // Memoized press handler
  const handlePress = useCallback(() => {
    onPress();
  }, [onPress]);

  // Function to handle styles conditionally
  const getIconContainerStyles = (hovered: boolean): ViewStyle[] => {
    const baseStyle = [
      { backgroundColor: primary },
      { borderColor: primary },
      styles.iconContainer as ViewStyle,
    ];
    if (!disabled && hovered) baseStyle.push(styles.iconContainerHovered);
    if (isCloseButton) baseStyle.push(styles.closeIconContainer);
    if (isDisabledCloseButton || disabled) baseStyle.push(styles.iconContainerDisabled);
    return baseStyle;
  };

  return (
    <Pressable disabled={disabled} testID={testID} onPress={handlePress}>
      {(hovered) => (
        <View style={getIconContainerStyles(hovered)}>
          <Icon
            nonScalingStroke
            name={iconName}
            color={isCloseButton ? (isDisabledCloseButton ? palette.grey5 : palette.navy) : palette.white}
            width={10}
          />
        </View>
      )}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  iconContainer: {
    width: 28,
    height: 28,
    borderRadius: 14,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 5,
    borderWidth: 0,
    cursor: 'pointer',
    ...ifWeb({
      transitionProperty: 'background-color, border-color',
    }),
  },
  iconContainerDisabled: {
    borderColor: palette.grey5,
  },
  iconContainerHovered: {
    backgroundColor: palette.navy,
  },
  closeIconContainer: {
    backgroundColor: palette.white,
    borderWidth: 2,
    borderColor: palette.navy,
  },
});

// Memoize the component to prevent unnecessary re-renders
export default React.memo(FindOnPageInputButton);
