import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';

import { StyledText, GradientBar, Pressable } from 'src/components';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { ifWeb, palette } from 'src/styles';

import { Link } from '../../../navigation/components/Link';

interface Props {
  text: string;
  link?: string;
  onPress?(): void;
  gradientBackground?: boolean;
  style?: StyleProp<ViewStyle>;
  testID?: string;
}

export const LaunchpadButton: React.FC<Props> = ({
  text,
  link,
  onPress,
  gradientBackground,
  style,
  testID,
}) => {
  const { primary } = useTheme();
  const content = (isHovered: boolean) => (
    <View
      style={[styles.wrapper, styles.wrapperShadow, isHovered && styles.wrapperShadowHovered, style]}
      testID={testID}
    >
      <GradientBar style={styles.background} gradientType={isHovered ? 'pvdGradient' : 'proGradient'} />
      <StyledText
        style={[
          styles.text,
          { color: primary },
          gradientBackground && styles.textGradientBg,
          !gradientBackground && isHovered && styles.textHoveredBg,
        ]}
      >
        {text}
      </StyledText>
    </View>
  );

  return link ? (
    <Link to={link} external>
      {content}
    </Link>
  ) : (
    <Pressable onPress={onPress}>{content}</Pressable>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: palette.white,
    borderRadius: 5,
    overflow: 'hidden',
  },
  wrapperShadow: {
    ...ifWeb({
      boxShadow: '0px 0px 15px 0px rgba(5, 144, 213, 0.20)',
    }),
  },
  wrapperShadowHovered: {
    ...ifWeb({
      boxShadow: '0px 0px 20px 0px rgba(5, 144, 213, 0.25)',
    }),
  },
  background: {
    ...StyleSheet.absoluteFillObject,
    zIndex: -1,
  },
  text: {
    textTransform: 'uppercase',
    fontSize: 13,
    fontWeight: '700',
    marginRight: 5,
  },
  textGradientBg: {
    color: palette.white,
  },
  textHoveredBg: {
    color: palette.navy,
  },
});
