import React, { useEffect, useState } from 'react';

import { ContentItemScreen } from 'src/components';
import { FeatureButtonWithSectionId } from 'src/components/ContentItemScreen/ContentItemScreen';
import { SpecialSectionIDs } from 'src/constants/constants';
import { useUserInfo } from 'src/features/auth/hooks';
import { DxTxItemScreen as DxTxItemScreenOld } from 'src/features/dxTx/screens/DxTxItemScreen.old';
import { useContentNotes } from 'src/features/notes';
import {
  OlyticsService,
  getMixpanelCBLocation,
  MixpanelEvent,
  MixpanelService,
} from 'src/features/tracking';
import { useContentHandouts } from 'src/hooks';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';

import { DxTxHeaderDescription } from '../components';
import { ConsensusStatementModal } from '../components/ConsensusStatementModal';
import { useDxTxItem } from '../hooks';

type Props = ScreenProps<Route.DxTxItem>;

export const DxTxItemScreenNew: React.FC<Props> = ({ route }) => {
  const { id, section, subsection, source, searchQuery } = route.params || {};
  const [isConsensusStatementModalOpen, setConsensusStatementModal] = useState(false);

  const { data, error, fetchStatus } = useDxTxItem(id);
  const notes = useContentNotes(id);

  const {
    shouldDisplayButton: showHandoutsButton,
    openPrintableHandoutsList,
    renderedModal: handoutsModal,
    numberOfHandouts,
  } = useContentHandouts({
    item: data,
  });

  // Olytics tracking
  useEffect(() => {
    if (data) {
      OlyticsService.fire({
        ClinicalBriefTitle: data.title,
        ClinicalBriefClassification: data.classifications?.join(', '),
      });
    }
  }, [data]);

  // Mixpanel tracking
  useEffect(() => {
    if (data) {
      MixpanelService.track(MixpanelEvent.DxTxView, {
        'Clinical Brief ID': data.id,
        'Clinical Brief Title': data.title,
        'Search Term Before Click Through': source === 'search' ? searchQuery : undefined,
        ...getMixpanelCBLocation(source),
      });
    }
  }, [data, source, searchQuery]);

  const handleConsensusStatementPress = () => {
    if (data) {
      MixpanelService.track(MixpanelEvent.ConsensusStatementView, {
        'Content ID': data.id,
        'Content Name': data.title,
      });
    }
  };

  const isSingleConsensusStatementLink = data?.consensusStatementsLinks?.length === 1;
  const isMultipleConsensusStatementLinks = (data?.consensusStatementsLinks ?? []).length > 1;

  const featureButtons: FeatureButtonWithSectionId[] = [
    {
      text: i18n.t('dxTx:consensusStatement'),
      icon: 'chevron-right',
      linkProps: isSingleConsensusStatementLink
        ? {
            to: data.consensusStatementsLinks[0].link,
            external: true,
            onPress: handleConsensusStatementPress,
          }
        : undefined,
      onPress: isMultipleConsensusStatementLinks ? () => setConsensusStatementModal(true) : undefined,
      hidden: !data?.consensusStatementsLinks.length,
    },
    {
      text: i18n.t('featureButtons:viewClinicalHandouts', { count: numberOfHandouts }),
      icon: 'chevron-right',
      onPress: openPrintableHandoutsList,
      hidden: !showHandoutsButton,
      sectionWithButton: SpecialSectionIDs.CLIENT_EDUCATION_HOME_CARE,
    },
  ];

  return (
    <>
      <ContentItemScreen
        title={i18n.t('screens:clinicalMonograph')}
        notes={notes}
        item={data}
        headerDescription={data && <DxTxHeaderDescription item={data} />}
        featureButtons={featureButtons}
        fetchStatus={fetchStatus}
        error={error}
        sectionToScrollTo={section}
        subsectionToScrollTo={subsection}
      />
      {handoutsModal}
      {isConsensusStatementModalOpen && data?.consensusStatementsLinks && (
        <ConsensusStatementModal
          links={data.consensusStatementsLinks}
          close={() => setConsensusStatementModal(false)}
          onLinkPress={handleConsensusStatementPress}
        />
      )}
    </>
  );
};

export const DxTxItemScreen: React.FC<Props> = (props) => {
  const {
    permissions: { hasAccessToStandards },
  } = useUserInfo();
  const Component = hasAccessToStandards ? DxTxItemScreenNew : DxTxItemScreenOld;

  return <Component {...props} />;
};
