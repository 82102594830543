import React, {
  forwardRef,
  PropsWithChildren,
  ReactNode,
  useId,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { StyleSheet, TextInput as BaseTextInput, TextInputProps } from 'react-native';

import { DEFAULT_MAX_FONT_SIZE_MULTIPLIER } from 'src/constants/constants';
import { IconName } from 'src/constants/types';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { useAndroidInputHack } from 'src/hooks/useAndroidInputHack';
import { fonts, palette, typography } from 'src/styles';

import { SecondaryButton } from './Buttons/SecondaryButton';
import { IconButton } from './IconButton';
import { InputBase, Props as InputBaseProps } from './InputBase';
import { LoadingIndicator } from './LoadingIndicator';

interface Props extends Omit<TextInputProps & InputBaseProps, 'editable'> {
  touched?: boolean;
  description?: string;
  iconName?: IconName | ReactNode;
  iconWidth?: number;
  button?: React.ComponentProps<typeof SecondaryButton>;
  isLoading?: boolean;
}

export interface InputItemRef {
  focus(): void;
  blur(): void;
}

export const InputItem = forwardRef<InputItemRef, PropsWithChildren<Props>>((props, ref) => {
  const { primary } = useTheme();
  const [isSecureTextVisible, setIsSecureTextVisible] = useState(false);
  const [focused, setFocused] = useState(false);

  const inputId = useId();
  const descriptionId = `${inputId}--description`;
  const errorId = `${inputId}--error`;
  const inputRef = useRef<any>();

  const androidHackPadding = useAndroidInputHack(props.value);

  useImperativeHandle(ref, () => ({
    focus() {
      inputRef.current?.focus();
    },
    blur() {
      inputRef.current?.blur();
    },
  }));

  const onFocus: Props['onFocus'] = (e) => {
    props.onFocus?.(e);
    setFocused(true);
  };

  const onBlur: Props['onBlur'] = (e) => {
    props.onBlur?.(e);
    setFocused(false);
  };

  const toggleSecureTextVisibility = () => {
    setIsSecureTextVisible((value) => !value);
  };

  const renderSecureTextSwitch = () => {
    const { secureTextEntry, error, touched } = props;
    const shouldShowError = !!error && touched;

    if (!secureTextEntry) {
      return;
    }
    return (
      <IconButton
        name={isSecureTextVisible ? 'eye-off' : 'eye'}
        onPress={toggleSecureTextVisibility}
        containerStyle={styles.secureTextIconContainer}
        color={shouldShowError ? palette.red : primary}
      />
    );
  };

  const renderButton = () => {
    const { button } = props;
    if (!button) return null;

    const { containerStyle, ...rest } = button;

    return <SecondaryButton containerStyle={[styles.button, containerStyle]} size="tiny" {...rest} />;
  };

  const {
    containerStyle,
    label,
    error,
    info,
    touched,
    style,
    secureTextEntry,
    placeholderTextColor,
    readOnly,
    description,
    children,
    isLoading,
    inputMode,
    ...rest
  } = props;
  const shouldShowError = !!error && touched;
  const isErrorDescribed = shouldShowError && typeof error === 'string';
  const isDescriptionDisplayed = !!description && !shouldShowError;

  return (
    <>
      <InputBase
        label={label}
        containerStyle={containerStyle}
        inputContainerStyle={{ paddingRight: androidHackPadding }}
        error={shouldShowError ? error : undefined}
        info={info}
        focused={focused}
        readOnly={readOnly}
        description={description}
        inputId={inputId}
        descriptionId={descriptionId}
      >
        <BaseTextInput
          autoCapitalize="none"
          autoCompleteType="off"
          autoCorrect={false}
          inputMode={inputMode || getInputModeByAutocomplete(rest.autoCompleteType)}
          {...rest}
          onFocus={onFocus}
          onBlur={onBlur}
          readOnly={readOnly}
          disabled={readOnly}
          id={inputId}
          style={[
            styles.input,
            shouldShowError && styles.inputError,
            secureTextEntry && styles.secureInput,
            readOnly && styles.disabled,
            style,
          ]}
          placeholderTextColor={placeholderTextColor ?? palette.grey4}
          secureTextEntry={secureTextEntry && !isSecureTextVisible}
          ref={inputRef}
          maxFontSizeMultiplier={DEFAULT_MAX_FONT_SIZE_MULTIPLIER}
          aria-describedby={isDescriptionDisplayed ? descriptionId : undefined}
          aria-errormessage={isErrorDescribed ? errorId : undefined}
          aria-invalid={!!shouldShowError}
        />
        {renderSecureTextSwitch()}
        {renderButton()}
        {isLoading && <LoadingIndicator size="small" style={styles.loadingIndicator} />}
        {children}
      </InputBase>
    </>
  );
});

const getInputModeByAutocomplete = (
  autoCompleteType: TextInputProps['autoCompleteType'],
): TextInputProps['inputMode'] => {
  switch (autoCompleteType) {
    case 'email':
      return 'email';
    case 'tel':
      return 'tel';
  }
};

const styles = StyleSheet.create({
  input: {
    minWidth: 0,
    flex: 1,
    width: 'auto',
    paddingHorizontal: 16,
    color: palette.grey8,
    ...typography.text2,
    fontFamily: fonts.sourceSans,
  },
  disabled: { color: palette.grey5 },
  inputError: {
    color: palette.red,
  },
  secureInput: {
    paddingRight: 50,
  },
  secureTextIconContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    width: 50,
  },
  button: {
    marginRight: 14,
    marginTop: 14,
  },
  loadingIndicator: {
    marginRight: 14,
    alignSelf: 'center',
  },
});
