import React, { CSSProperties, useCallback, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { IconButton, LoadingIndicator, Popover, StyledText } from 'src/components';
import { Video } from 'src/components/Video.web';
import { ModalPortal } from 'src/contexts/portals';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { isWeb } from 'src/helpers/common';
import { handleImageRightClick } from 'src/helpers/functions';
import { i18n } from 'src/locale';
import { palette, typography } from 'src/styles';

import { DxTxImageModal } from './DxTxImageModal';
import { DxTxMediaLink as BaseComponent } from './DxTxMediaLink';
import { DxTxVideoModal } from './DxTxVideoModal';
import { useDxTxImage } from '../hooks';

type Props = React.ComponentProps<typeof BaseComponent>;

export const DxTxMediaLink: React.FC<Props> = ({ children, id }) => {
  const { primary } = useTheme();
  const [isModalOpen, setModal] = useState<boolean>(false);
  const [autoPlay, setAutoPlay] = useState<boolean>(false);

  const { data, isLoading } = useDxTxImage(id);
  const imageUrl = data?.imagesUrls?.[0] || '';
  const videoUrl = data?.videoUrl || '';

  const handleExpandButtonPress = useCallback((videoAutoPlay?: boolean) => {
    videoAutoPlay && setAutoPlay(true);
    setModal(true);
  }, []);

  const closeModal = () => {
    setAutoPlay(false);
    setModal(false);
  };

  const renderMedia = (videoUrl?: string, imageUrl?: string) => {
    if (videoUrl) {
      return <Video onIconPress={() => handleExpandButtonPress(true)} videoUrl={videoUrl} />;
    }
    const cssStyles: CSSProperties = { height: '100%', width: '100%' };
    return <img src={imageUrl} onContextMenu={handleImageRightClick} style={cssStyles} alt="media" />;
  };

  return (
    <>
      <Popover
        contentWrapperStyles={styles.popoverWrapper}
        backgroundColor={palette.white}
        arrowSize={10}
        content={
          <View style={styles.wrapper}>
            {isLoading ? (
              <LoadingIndicator style={styles.loader} size="small" />
            ) : data ? (
              <>
                <View style={styles.header}>
                  <StyledText style={[typography.body1ShortSemiBold, styles.title]}>
                    {data.figureTitle}
                  </StyledText>
                  <IconButton name="expand" color={primary} width={12} onPress={handleExpandButtonPress} />
                </View>
                {renderMedia(videoUrl, imageUrl)}
                {data.imagesUrls?.length > 1 && (
                  <StyledText style={[typography.body2, styles.multipleImagesIndicator]}>
                    {i18n.t(`dxTx:nthOf`, { nth: 1, count: data.imagesUrls.length })}
                  </StyledText>
                )}
              </>
            ) : null}
          </View>
        }
      >
        {children}
      </Popover>
      {isWeb && isModalOpen && (
        <ModalPortal>
          {videoUrl && data ? (
            <DxTxVideoModal autoPlay={autoPlay} close={closeModal} item={data} />
          ) : (
            <DxTxImageModal id={id} close={closeModal} />
          )}
        </ModalPortal>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  popoverWrapper: {
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  wrapper: {
    minWidth: 200,
    maxWidth: 200,
    padding: 16,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 8,
  },
  title: {
    color: palette.navy,
    marginRight: 8,
  },
  loader: {
    marginVertical: 20,
  },
  multipleImagesIndicator: {
    marginTop: 8,
    marginBottom: -8,
    textAlign: 'center',
  },
});
