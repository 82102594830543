import { Suspense, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { Icon, StyledText, GradientBar, Pressable, LoadingIndicator } from 'src/components';
import { useUserInfo } from 'src/features/auth/hooks';
import { useTheme } from 'src/features/auth/hooks/useTheme';
import { Calculator, CalculatorRef } from 'src/features/calculator';
import { i18n } from 'src/locale';
import { CalculatorItem as CalculatorItemOld } from 'src/navigation/components/SideBar/CalculatorItem.old';
import { palette, typography, ifWeb } from 'src/styles';

interface Props {
  onOpen?(): void;
}

export const CalculatorItemNew: React.FC<Props> = ({ onOpen }) => {
  const { primary } = useTheme();
  const [isOpen, setOpen] = useState(false);
  const calculatorRef = useRef<CalculatorRef>(null);
  const { isPro } = useUserInfo();

  const onPress = () => {
    if (!isOpen) {
      onOpen?.();
    }
    setOpen(!isOpen);
    calculatorRef.current?.focus();
  };

  const gradientType = isOpen
    ? isPro
      ? 'proSelectedGradient'
      : 'proGradient'
    : isPro
    ? 'standardsSideBarSelectedGradient'
    : 'whiteGradient';

  return (
    <>
      <Pressable onPress={onPress} testID="calculator-item">
        {(hovered) => (
          <View
            style={[
              isPro ? styles.calcButtonWrapperDark : styles.calcButtonWrapper,
              hovered ? styles.hoveredBackground : styles.transparentBackground,
            ]}
          >
            <GradientBar
              gradientType={gradientType}
              hideGradient={isOpen}
              style={[styles.calcButton, isOpen && styles.calcBackground]}
            >
              <View style={styles.itemInnerWrapper}>
                <StyledText
                  style={[
                    styles.itemText,
                    isPro && styles.itemTextPadding,
                    isPro && styles.itemTextWhite,
                    !isPro && hovered && { color: primary },
                    isOpen && styles.itemTextSelected,
                  ]}
                >
                  {i18n.t('tools:calculator')}
                </StyledText>
                <View
                  style={[
                    { transform: [{ rotate: isOpen ? '-90deg' : '90deg' }] },
                    styles.itemChevronWrapper,
                  ]}
                >
                  <Icon
                    name="chevron-right"
                    width={10}
                    color={isOpen ? palette.navy : isPro ? palette.grey5 : palette.navy}
                  />
                </View>
              </View>
            </GradientBar>
          </View>
        )}
      </Pressable>
      {isOpen && (
        <View style={styles.contentWrapper}>
          <View style={styles.content}>
            <Suspense fallback={<LoadingIndicator />}>
              <Calculator ref={calculatorRef} />
            </Suspense>
          </View>
        </View>
      )}
    </>
  );
};
const styles = StyleSheet.create({
  transparentBackground: {
    backgroundColor: palette.transparent,
    transition: 'all 250ms',
  },
  hoveredBackground: {
    backgroundColor: palette.hoverOverlay,
    transition: 'all 250ms',
  },
  calcButtonWrapper: {
    borderTopWidth: 1,
    borderColor: palette.grey2,
  },
  calcButtonWrapperDark: {
    borderTopWidth: 1,
    borderColor: palette.grey7,
  },
  calcButton: {
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
  calcBackground: {
    backgroundColor: palette.white,
  },
  itemInnerWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  itemText: {
    ...typography.body3Bold,
    color: palette.navy,
    ...ifWeb({
      transitionProperty: 'color',
    }),
  },
  itemTextPadding: {
    paddingRight: 8,
  },
  itemTextWhite: {
    color: palette.white,
  },
  itemTextSelected: {
    color: palette.navy,
  },
  itemChevronWrapper: {
    transitionProperty: 'transform',
  },
  contentWrapper: {
    overflowY: 'hidden',
    backgroundColor: palette.white,
  },
  content: {
    paddingHorizontal: 16,
    paddingBottom: 16,
    height: 500,
  },
});

export const CalculatorItem: React.FC<Props> = (props) => {
  const {
    permissions: { hasAccessToStandards },
  } = useUserInfo();
  const Component = hasAccessToStandards ? CalculatorItemNew : CalculatorItemOld;

  return <Component {...props} />;
};
